import { ApolloError, gql, MutationFunction, MutationResult, QueryResult } from '@apollo/client';
import { Mutation, Query } from '@apollo/client/react/components';
import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import { Alert } from '../components/Alert';
import { Empty } from '../components/Empty';
import { withUser } from '../hoc/withUser';
import { Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';

const NOTIFICATIONS = gql`
    query Notifications(
        $skip: Int
        $take: Int
    ) {
        notifications(
            skip: $skip
            take: $take
            sortBy: "createdAt"
            sortOrder: "desc"
        ) {
            count
            edges {
                id
                type
                readAt
                createdAt
                drops
                friend {
                    nickname
                }
            }
        }
    }
`;

const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
    mutation MarkAllNotificationsAsRead {
        markAllNotificationsAsRead
    }
`;

type NotificationsProps = {
    insets: Insets;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

export const Notifications = connect(mapStateToProps)(withUser<NotificationsProps>(({ insets }): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();

    const [
        skip,
        setSkip
    ] = useState(0);
    const [
        notifications,
        setNotifications
    ] = useState<any[]>([]);

    return (
        <Query
            fetchPolicy="no-cache"
            query={NOTIFICATIONS}
            variables={{
                skip,
                take: 20
            }}
            onError={(err: ApolloError) => {
                //
            }}
            onCompleted={(data: any) => {
                setNotifications([
                    ...notifications,
                    ...data.notifications.edges
                ]);
            }}
        >
            {({ data, loading }: QueryResult) => (
                <div>
                    {notifications.map(({ id, type, readAt, createdAt, drops, friend }: any, index: number) => (
                        <div key={id}>
                            <Alert
                                id={id}
                                type={type}
                                readAt={readAt}
                                createdAt={createdAt}
                                drops={drops}
                                friend={friend}
                            />
                            {index !== notifications.length - 1 && (
                                <Divider />
                            )}
                        </div>
                    ))}
                    {data && (
                        <InView
                            style={{
                                height: Number(data.notifications.count !== notifications.length)
                            }}
                            onChange={visible => {
                                if (visible && data.notifications.count > notifications.length) {
                                    setSkip(notifications.length);
                                }
                            }}
                        />
                    )}
                    {loading && (
                        <Box padding={2}>
                            <Box textAlign="center">
                                <CircularProgress />
                            </Box>
                        </Box>
                    )}
                    {loading === false && notifications.length === 0 && (
                        <Empty
                            top={128}
                            bottom={58}
                        />
                    )}
                    <Box
                        style={{
                            paddingTop: 58
                        }}
                    />
                    <Box
                        sx={{
                            position: 'fixed',
                            inset: `auto auto ${insets.bottom}px`,
                            zIndex: 999,
                            width: '100%',
                            maxWidth: 444,
                            padding: 1,
                            textAlign: 'center',
                            borderTop: theme => `1px solid ${theme.palette.divider}`,
                            backgroundColor: '#fff',
                            color: '#4f345a'
                        }}
                    >
                        <Mutation
                            mutation={MARK_ALL_NOTIFICATIONS_AS_READ}
                            variables={{

                            }}
                            onError={(err: ApolloError) => {
                                enqueueSnackbar(err.message, {
                                    variant: 'error'
                                });
                            }}
                            onCompleted={(data: any) => {
                                setNotifications(notifications => notifications.map(notification => ({ ...notification, readAt: Date.now() })));
                            }}
                        >
                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                <LoadingButton
                                    loading={loading}
                                    color="inherit"
                                    size="large"
                                    onClick={() => {
                                        mutate();
                                    }}
                                    startIcon={
                                        <Check />
                                    }
                                >
                                    Бүгдийг уншсан болгох
                                </LoadingButton>
                            )}
                        </Mutation>
                    </Box>
                </div>
            )}
        </Query>
    );
}));
