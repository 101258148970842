import { gql } from '@apollo/client';

export const CurrentUserFields = gql`
    fragment CurrentUserFields on User {
        id
        phone
        avatar
        nickname
        drops
        currentTree {
            id
            drops
            requiredDrops
            progress
            completed
            type {
                name
                code
            }
            place {
                name
                location
                partner {
                    name
                    logo
                }
            }
        }
    }
`;