import { Box, BoxProps } from '@mui/material';
import { useRef } from 'react';
import { useBoundingclientrect } from 'rooks';
import { Position } from '../types';

type AnchorProps = Omit<BoxProps, 'children'> & {
    children: (position: Position) => JSX.Element;
};

export const Anchor = (props: AnchorProps): JSX.Element | null => {
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const getBoundingClientRect = useBoundingclientrect(anchorRef);

    const {
        children,
        ...other
    } = props;

    return (
        <Box {...other}>
            <div ref={anchorRef}>
                {getBoundingClientRect && children({
                    x: (getBoundingClientRect.left + getBoundingClientRect.right) / window.innerWidth * 50,
                    y: (getBoundingClientRect.top + getBoundingClientRect.bottom) / window.innerHeight * 50
                })}
            </div>
        </Box>
    );
};;;