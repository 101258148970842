import { Box, SvgIcon, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { Empty as EmptyIcon } from '../assets/svg';
import { Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';

type EmptyProps = {
    insets: Insets;
    action?: React.ReactNode;
    title?: string;
    description?: string;
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

export const Empty = connect(mapStateToProps)((props: EmptyProps): JSX.Element => {
    const {
        insets,
        action,
        title,
        description = 'Мэдээлэл олдсонгүй',
        top = 0,
        left = 0,
        right = 0,
        bottom = 0
    } = props;

    return (
        <Box
            top={top + insets.top}
            left={left}
            right={right}
            bottom={bottom + insets.bottom}
            sx={{
                position: 'absolute',
                fontSize: 48,
                paddingX: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <SvgIcon
                component={EmptyIcon}
                inheritViewBox
                sx={{
                    fontSize: 64
                }}
            />
            <Box marginTop={1}>
                {title && (
                    <Typography
                        variant="subtitle1"
                        color="text.disabled"
                        textAlign="center"
                        gutterBottom
                    >
                        {title}
                    </Typography>
                )}
                <Typography
                    variant="body1"
                    color="text.disabled"
                    textAlign="center"
                >
                    {description}
                </Typography>
                {action && (
                    <Box
                        lineHeight={0}
                        marginTop={2}
                        textAlign="center"
                    >
                        {action}
                    </Box>
                )}
            </Box>
        </Box>
    );
});
