import { Box, Button, CardActionArea, Container, SvgIcon, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { QuestionMark } from '../assets/svg';
import { PageHeader } from '../components/PageHeader';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';
import { dark } from '../theme/muiTheme';

type HelpProps = {
    platform: string;
    insets: Insets;
    setColors: (colors: Colors) => void;
};

const mapStateToProps = ({ app }: RootState) => ({
    platform: app.platform,
    insets: app.insets
});

const mapDispatchToProps = {
    setColors
};

export const Help = connect(mapStateToProps, mapDispatchToProps)(withUser<HelpProps>((props): JSX.Element => {
    const {
        platform,
        insets,
        setColors
    } = props;

    useEffect(() => {
        changeThemeColor('#0c4da2');
        setColors({
            top: '#0c4da2',
            bottom: '#fff'
        });
    }, []);

    return (
        <Container maxWidth="xs">
            <PageHeader
                color="#0c4da2"
                closeButtonProps={{
                    component: Link,
                    to: '/home'
                }}
            />
            <ThemeProvider theme={dark}>
                <Box
                    sx={{
                        position: 'fixed',
                        inset: `${insets.top + 64}px auto auto`,
                        width: '100%',
                        maxWidth: 444,
                        padding: 2,
                        paddingTop: 0,
                        backgroundColor: 'primary.main'
                    }}
                >
                    <Box textAlign="center">
                        <SvgIcon
                            component={QuestionMark}
                            inheritViewBox
                            fontSize="large"
                        />
                    </Box>
                    <Typography
                        variant="h5"
                        textAlign="center"
                        color="text.primary"
                        sx={{
                            marginTop: 1
                        }}
                    >
                        Тоглоомын дүрэм
                    </Typography>
                </Box>
            </ThemeProvider>
            <Box
                style={{
                    paddingTop: 162
                }}
            />
            <Box padding={2}>
                <Typography
                    variant="body2"
                    color="text.secondary"
                >
                    Та ус цуглуулж виртуал мод тарина. Таны ургуулж дууссан виртуал мод бүр <strong style={{ color: '#0c4da2' }}>БАЙГАЛЬД ТАРИГДАХ</strong> болно. Тоглоом ердөө 3 дүрэмтэй.
                </Typography>
                <Typography
                    variant="subtitle1"
                    textTransform="uppercase"
                    gutterBottom
                    sx={{
                        marginTop: 3
                    }}
                >
                    Алхалтаа идэвхжүүл
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        marginBottom: 1
                    }}
                >
                    Өдөр бүрийн алхалтыг тань тооцоолж ус бэлэглэнэ. 1000 алхаад 1литр ус.
                </Typography>
                {platform === 'ios'
                    ?
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        disableElevation
                        onClick={() => {
                            window.ReactNativeWebView?.postMessage(JSON.stringify({
                                type: 'ACTIVE'
                            }));
                        }}
                        sx={{
                            textTransform: 'none'
                        }}
                    >
                        Идэвхжүүлэх
                    </Button>
                    :
                    <CardActionArea
                        onClick={() => {
                            window.ReactNativeWebView?.postMessage(JSON.stringify({
                                type: 'ACTIVE'
                            }));
                        }}
                        sx={{
                            display: 'inline-block',
                            width: 185,
                            height: 40,
                            borderRadius: '2px'
                        }}
                    >
                        <Box
                            src="/static/images/google-signin-button-dark.png"
                            component="img"
                            sx={{
                                height: 46,
                                width: 'auto',
                                margin: '-3px'
                            }}
                        />
                    </CardActionArea>
                }
                <Typography
                    variant="subtitle1"
                    textTransform="uppercase"
                    gutterBottom
                    sx={{
                        marginTop: 3
                    }}
                >
                    Найзаа уриад ус бэлэглэ эсвэл хулгайл
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        marginBottom: 1
                    }}
                >
                    Найзаа урьж яах юм бэ гэж үү? Найздаа ус бэлэглэж хамтдаа мод тарьж болно. Эсвэл найзынхаа усыг хулгайлж бас болно. Танд өдөрт 5 удаа л хулгай хийх эрх байгааг анхаарна уу.
                </Typography>
                <Button
                    component={Link}
                    to="/add/friend"
                    variant="contained"
                    color="info"
                    size="small"
                    disableElevation
                    sx={{
                        textTransform: 'none'
                    }}
                >
                    Найзаа урих
                </Button>
                <Typography
                    variant="subtitle1"
                    textTransform="uppercase"
                    gutterBottom
                    sx={{
                        marginTop: 3
                    }}
                >
                    Бонус оноогоо ашигла
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        marginBottom: 1
                    }}
                >
                    PayOn бонус оноогоо ашиглан модоо ургуулж болно. Бүр хурдан ургуулах бол мөнгөөр шийдэж ч болно.
                </Typography>
                <Button
                    component={Link}
                    to="/shop"
                    variant="contained"
                    color="info"
                    size="small"
                    disableElevation
                    sx={{
                        textTransform: 'none'
                    }}
                >
                    Ус худалдаж авах
                </Button>
            </Box>
        </Container>
    );
}));
