import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Box, Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Fetching } from '../components/Fetching';
import { FriendDrops } from '../components/FriendDrops';
import { PageHeader } from '../components/PageHeader';
import { StealableBubbles } from '../components/StealableBubbles';
import { StealCharges } from '../components/StealCharges';
import { TreeAnimation } from '../components/TreeAnimation';
import { withUser } from '../hoc/withUser';
import { changeThemeColor, getCurrentHours } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';

const USER = gql`
    query User($id: String!) {
        stealCharges
        user(id: $id) {
            id
            avatar
            nickname
            currentTree {
                progress
                type {
                    code
                }
            }
        }
    }
`;

type FriendProps = {
    setColors: (colors: Colors) => void;
    insets: Insets;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setColors
};

export const Friend = connect(mapStateToProps, mapDispatchToProps)(withUser<FriendProps>((props): JSX.Element => {
    const { id } = useParams();
    const {
        setColors,
        insets
    } = props;

    const nightStartTime = parseInt(process.env.REACT_APP_NIGHT_START_TIME || '19');
    const nightEndTime = parseInt(process.env.REACT_APP_NIGHT_END_TIME || '5');
    const currentHours = getCurrentHours();

    const [
        environment
    ] = useState<'dark' | 'light'>(currentHours >= nightStartTime || currentHours <= nightEndTime ? 'dark' : 'light');
    const [
        charges,
        setCharges
    ] = useState(0);

    useEffect(() => {
        switch (environment) {
            case 'dark':
                changeThemeColor('#5660cc');
                setColors({
                    top: '#5660cc',
                    bottom: '#2f739d'
                });
                break;
            case 'light':
                changeThemeColor('#a7d5eb');
                setColors({
                    top: '#a7d5eb',
                    bottom: '#90c7fa'
                });
                break;
        }
    }, []);

    return (
        <Query
            fetchPolicy="no-cache"
            query={USER}
            variables={{
                id
            }}
            onError={(err: ApolloError) => {
                //
            }}
            onCompleted={({ stealCharges }: any) => {
                setCharges(stealCharges);
            }}
        >
            {({ data, loading }: QueryResult) => {
                if (loading) {
                    return (
                        <Fetching />
                    );
                }

                const {
                    id,
                    avatar,
                    nickname,
                    currentTree
                } = data.user;

                return (
                    <Box
                        sx={{
                            position: 'fixed',
                            inset: `${insets.top}px 0 ${insets.bottom}px`,
                            display: 'flex'
                        }}
                    >
                        <Container
                            maxWidth="xs"
                            sx={{
                                position: 'relative',
                                flex: 1,
                                overflow: 'hidden'
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    inset: '12px auto auto 16px',
                                    zIndex: 999
                                }}
                            >
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <FriendDrops
                                        avatar={avatar}
                                        nickname={nickname}
                                    />
                                    <StealCharges
                                        charges={charges}
                                    />
                                </Stack>
                            </Box>
                            <PageHeader
                                color="transparent"
                                dark={false}
                                closeButtonProps={{
                                    component: Link,
                                    to: '/steal'
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    inset: 0,
                                    backgroundImage: `url(/static/images/environments/${environment}/sky.png)`,
                                    backgroundSize: 'cover'
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        inset: 0,
                                        backgroundSize: '200% auto',
                                        backgroundImage: 'url(/static/images/clouds.png)',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center 15%'
                                    }}
                                >
                                    <div className="clouds">
                                        {[1, 2, 3].map(value => (
                                            <img
                                                key={value}
                                                src={`/static/images/cloud-${value}.png`}
                                                className={`cloud cloud-${value}`}
                                            />
                                        ))}
                                    </div>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            inset: 'auto 0px 0px',
                                            paddingTop: '100%',
                                            backgroundSize: '100% auto',
                                            backgroundImage: `url(/static/images/environments/${environment}/ground.png)`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'bottom'
                                        }}
                                    >
                                        <StealableBubbles
                                            charges={charges}
                                            onChangeCharges={setCharges}
                                            userId={id}
                                            userNickname={nickname}
                                            sx={{
                                                position: 'absolute',
                                                inset: '-120px 0 auto'
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                inset: 'auto auto 50% 50%',
                                                width: '60%',
                                                transform: 'translateX(-50%)'
                                            }}
                                        >
                                            <TreeAnimation
                                                progress={currentTree.progress}
                                                code={currentTree.type.code}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                );
            }}
        </Query>
    );
}));
