import { ApolloError, gql, MutationFunction, MutationResult, useQuery } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Card, CardActionArea, Container, Dialog, Grid, InputAdornment, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DebitCards, Gift, Payment, Shop as ShopIcon, Thief } from '../assets/svg';
import { PageHeader } from '../components/PageHeader';
import { RadioDrops } from '../components/RadioDrop';
import { RadioMethod } from '../components/RadioMethod';
import { TreeAnimation } from '../components/TreeAnimation';
import { WayToCollectWater } from '../components/WayToCollectWater';
import { CurrentUserFields } from '../fragments/current-user-fields';
import { withUser } from '../hoc/withUser';
import { changeThemeColor, getValidationErrorMessage, parseErrors } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors } from '../redux/app/reducer';
import { setUser } from '../redux/auth/actions';
import { RootState } from '../redux/configureStore';

const PAYON_POINTS = gql`
    query PayonPoints {
        payonPoints
    }
`;

const BUY_DROPS_WITH_POINTS = gql`
    ${CurrentUserFields}
    mutation BuyDropsWithPoints($drops: Int) {
        buyDropsWithPoints(input: {
            drops: $drops
        }) {
            ...CurrentUserFields
        }
    }
`;

type ShopProps = {
    setColors: (colors: Colors) => void;
    setUser: (user: any) => void;
    user: any;
    platform: string;
};

const mapStateToProps = ({ app }: RootState) => ({
    platform: app.platform
});

const mapDispatchToProps = {
    setColors,
    setUser
};

export const Shop = connect(mapStateToProps, mapDispatchToProps)(withUser<ShopProps>((props): JSX.Element => {
    const redirect = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const {
        setColors,
        setUser,
        user,
        platform
    } = props;

    const [
        drops,
        setDrops
    ] = useState<number | null>(null);
    const [
        points,
        setPoints
    ] = useState(0);
    const [
        open,
        setOpen
    ] = useState(false);
    const [
        method,
        setMethod
    ] = useState<string | null>(null);
    const [
        neededDrops
    ] = useState(user.currentTree.requiredDrops - user.currentTree.drops);

    const { refetch } = useQuery(PAYON_POINTS, {
        fetchPolicy: 'no-cache',
        onError(err) {
            enqueueSnackbar(err.message, {
                variant: 'error'
            });
        },
        onCompleted(data) {
            setPoints(data.payonPoints);
        }
    });

    useEffect(() => {
        changeThemeColor('#0c4da2');
        setColors({
            top: '#0c4da2',
            bottom: '#fff'
        });
    }, []);

    useEffect(() => {
        setMethod(null);
    }, [open]);

    const amount = (method: 'payon' | 'loyalty'): number => {
        if (drops === null) {
            return 0;
        }
        switch (method) {
            case 'payon':
                return drops * parseInt(process.env.REACT_APP_PRICE_PER_LITRE ?? '0');
            case 'loyalty':
                return drops * parseInt(process.env.REACT_APP_LOYALTY_POINTS_PER_LITRE ?? '0');
        }
    };

    const formatNumber = (value: string | number) => {
        return (
            <NumericFormat
                value={value}
                displayType="text"
                thousandSeparator=","
            />
        );
    };

    return (
        <Container maxWidth="xs">
            <Dialog
                maxWidth="xs"
                open={drops !== null && open}
                onClose={() => setOpen(false)}
                fullWidth
            >
                <Box padding={2}>
                    <Typography variant="subtitle1">
                        Төлбөр төлөх
                    </Typography>
                    <Stack
                        spacing={1}
                        sx={{
                            marginTop: 2
                        }}
                    >
                        <RadioMethod
                            avatarSrc="/static/images/coin.png"
                            amount={amount('payon')}
                            suffix="₮"
                            price={parseInt(process.env.REACT_APP_PRICE_PER_LITRE ?? '0')}
                            checked={method === 'payon'}
                            onChangeValue={() => {
                                setMethod('payon');
                            }}
                        />
                        <div>
                            <RadioMethod
                                disabled={points < amount('loyalty')}
                                avatarSrc="/static/images/coin.png"
                                amount={amount('loyalty')}
                                suffix=" оноо"
                                price={parseInt(process.env.REACT_APP_LOYALTY_POINTS_PER_LITRE ?? '0')}
                                checked={method === 'loyalty'}
                                onChangeValue={() => {
                                    setMethod('loyalty');
                                }}
                            />
                            <Typography
                                variant="body2"
                                textAlign="right"
                                sx={{
                                    marginTop: '4px'
                                }}
                            >
                                <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Таны үлдэгдэл оноо:</span> <strong>{formatNumber(points)}</strong>
                            </Typography>
                        </div>
                    </Stack>
                    <Mutation
                        mutation={BUY_DROPS_WITH_POINTS}
                        variables={{
                            drops
                        }}
                        onError={(err: ApolloError) => {
                            const message = err.message === 'Bad Request Exception'
                                ? getValidationErrorMessage(parseErrors(err), 'drops')
                                : err.message;
                            enqueueSnackbar(message, {
                                variant: 'error'
                            });
                        }}
                        onCompleted={(data: any) => {
                            setUser(data.buyDropsWithPoints);
                            setOpen(false);
                            setDrops(null);
                            refetch();
                            enqueueSnackbar(`Таны ${amount('loyalty')} оноог ${drops} литр ус болгон шилжүүллээ.`, {
                                variant: 'success'
                            });
                        }}
                    >
                        {(mutate: MutationFunction, { loading }: MutationResult) => (
                            <LoadingButton
                                disabled={method === null}
                                loading={loading}
                                variant="contained"
                                color="primary"
                                disableElevation
                                fullWidth
                                onClick={() => {
                                    if (method === 'loyalty') {
                                        mutate();
                                    }
                                    if (method === 'payon') {
                                        window.ReactNativeWebView?.postMessage(JSON.stringify({
                                            type: 'LOYALTY',
                                            amount: amount('payon')
                                        }));
                                        setOpen(false);
                                        setDrops(null);
                                    }
                                }}
                                sx={{
                                    marginTop: 2
                                }}
                            >
                                Төлөх
                            </LoadingButton>
                        )}
                    </Mutation>
                </Box>
            </Dialog>
            <PageHeader
                svgIcon={ShopIcon}
                title="Дэлгүүр"
                color="#0c4da2"
                closeButtonProps={{
                    onClick: () => {
                        redirect(-1);
                    }
                }}
            />
            <Box
                style={{
                    paddingTop: 64
                }}
            />
            <Box padding={2}>
                <Card
                    elevation={0}
                    sx={{
                        border: '1px solid rgba(219, 231, 241, 0.5)',
                        backgroundColor: '#f2f9ff'
                    }}
                >
                    <Box padding={2}>
                        <Grid
                            columnSpacing={1}
                            container
                        >
                            {[5, 10, 20].map(value => (
                                <Grid
                                    key={value}
                                    item
                                    xs
                                >
                                    <RadioDrops
                                        drops={value}
                                        checked={drops === value}
                                        onChangeValue={() => {
                                            setDrops(value);
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <TextField
                            label="Усны хэмжээ"
                            variant="outlined"
                            value={drops || ''}
                            onChange={e => setDrops(parseInt(e.target.value))}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        литр
                                    </InputAdornment>
                                )
                            }}
                            sx={{
                                marginY: 1,
                                backgroundColor: '#fff'
                            }}
                        />
                        <Button
                            disabled={Boolean(drops) === false}
                            variant="contained"
                            color="primary"
                            disableElevation
                            fullWidth
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            Худалдаж авах
                        </Button>
                    </Box>
                </Card>
                {neededDrops !== 0 && (
                    <Card
                        elevation={0}
                        sx={{
                            marginTop: 2,
                            border: '1px solid rgba(212, 236, 217, 0.5)',
                            backgroundColor: '#f5fff2'
                        }}
                    >
                        <Box
                            paddingX={2}
                            paddingY={4}
                        >
                            <Grid
                                alignItems="center"
                                columnSpacing={3}
                                container
                            >
                                <Grid
                                    xs={4}
                                    item
                                >
                                    <TreeAnimation
                                        progress={100}
                                        code={user.currentTree.type.code}
                                    />
                                </Grid>
                                <Grid
                                    xs={8}
                                    item
                                    sx={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography variant="subtitle1">
                                        Би бүрэн ургахад <br /><span style={{ color: '#13a456' }}>{neededDrops} литр</span> ус дутуу байна.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        disableElevation
                                        onClick={() => {
                                            setDrops(neededDrops);
                                            setOpen(true);
                                        }}
                                        sx={{
                                            marginTop: 2
                                        }}
                                    >
                                        Бүрэн ургуулах
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                )}
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{
                        marginTop: 2
                    }}
                >
                    Бусад боломжууд
                </Typography>
                <Box marginTop={1}>
                    <Stack spacing={1}>
                        <WayToCollectWater
                            primary="Алхах"
                            secondary="Идэвхжүүлээд өдөр бүрийн алхалтын тоогоороо ус аваарай"
                            avatar={
                                <Box
                                    component="img"
                                    src="/static/images/walking.png"
                                    sx={{
                                        width: 56,
                                        height: 'auto'
                                    }}
                                />
                            }
                        >
                            <Stack
                                alignItems="flex-start"
                                spacing={2}
                                sx={{
                                    marginTop: 1
                                }}
                            >
                                <Typography variant="subtitle2">
                                    1000 алхалт = 1 литр ус
                                </Typography>
                                {platform === 'ios'
                                    ?
                                    <Button
                                        variant="contained"
                                        color="info"
                                        size="small"
                                        disableElevation
                                        onClick={() => {
                                            window.ReactNativeWebView?.postMessage(JSON.stringify({
                                                type: 'ACTIVE'
                                            }));
                                        }}
                                        sx={{
                                            textTransform: 'none'
                                        }}
                                    >
                                        Идэвхжүүлэх
                                    </Button>
                                    :
                                    <CardActionArea
                                        onClick={() => {
                                            window.ReactNativeWebView?.postMessage(JSON.stringify({
                                                type: 'ACTIVE'
                                            }));
                                        }}
                                        sx={{
                                            display: 'inline-block',
                                            width: 185,
                                            height: 40,
                                            borderRadius: '2px'
                                        }}
                                    >
                                        <Box
                                            src="/static/images/google-signin-button-dark.png"
                                            component="img"
                                            sx={{
                                                height: 46,
                                                width: 'auto',
                                                margin: '-3px'
                                            }}
                                        />
                                    </CardActionArea>
                                }
                            </Stack>
                        </WayToCollectWater>
                        <WayToCollectWater
                            primary="Бэлэг авах"
                            secondary="Найзаа нэмээд найзаасаа ус бэлгэнд аваарай"
                            avatar={(
                                <Avatar
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        backgroundColor: 'rgba(225, 205, 23, 0.22)'
                                    }}
                                >
                                    <SvgIcon
                                        component={Gift}
                                        fontSize="large"
                                        inheritViewBox
                                    />
                                </Avatar>
                            )}
                        />
                        <WayToCollectWater
                            primary="Найзаасаа хулгайлах"
                            secondary="Найз чинь модоо услахгүй байна уу? Тэгвэл энд дараад үз дээ"
                            avatar={(
                                <Avatar
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        backgroundColor: 'rgba(52, 23, 225, 0.22)'
                                    }}
                                >
                                    <SvgIcon
                                        component={Thief}
                                        fontSize="large"
                                        inheritViewBox
                                    />
                                </Avatar>
                            )}
                        />
                        <WayToCollectWater
                            primary="PayOn бонус оноогоо ашиглах"
                            secondary="Цуглуулсан оноогоо ашиглан хэрэгтэй усаа аваарай"
                            avatar={(
                                <Avatar
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        backgroundColor: 'rgba(23, 201, 225, 0.22)'
                                    }}
                                >
                                    <SvgIcon
                                        component={DebitCards}
                                        fontSize="large"
                                        inheritViewBox
                                    />
                                </Avatar>
                            )}
                        />
                        <WayToCollectWater
                            primary="Худалдаж авах"
                            secondary="Мэдээж төгрөгөөр усаа худалдан авах ч боломжтой"
                            avatar={(
                                <Avatar
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        backgroundColor: 'rgba(88, 225, 23, 0.22)'
                                    }}
                                >
                                    <SvgIcon
                                        component={Payment}
                                        fontSize="large"
                                        inheritViewBox
                                    />
                                </Avatar>
                            )}
                        />
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
}));
