import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Avatar, Box, Button, Card, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const CURRENT_USER = gql`
    query CurrentUser {
        currentUser {
            avatar
            nickname
            drops
        }
    }
`;

const TREES = gql`
    query Trees {
        currentUser {
            trees {
                count
            }
        }
    }
`;

const CERTIFICATES = gql`
    query Certificates {
        currentUserCertificates {
            count
        }
    }
`;

const FRIENDS = gql`
    query Friends {
        friends {
            count
        }
    }
`;

export const UserDetails = (): JSX.Element => {
    const [
        user,
        setUser
    ] = useState({
        avatar: '',
        nickname: '',
        drops: 0
    });

    return (
        <Card variant="outlined">
            <Box padding={2}>
                <Query
                    fetchPolicy="no-cache"
                    query={CURRENT_USER}
                    onError={(err: ApolloError) => {
                        //
                    }}
                    onCompleted={({ currentUser }: any) => {
                        setUser(currentUser);
                    }}
                >
                    {({ loading }: QueryResult) => (
                        <Grid
                            columnSpacing={2}
                            container
                        >
                            <Grid item>
                                {loading
                                    ?
                                    <Skeleton
                                        variant="circular"
                                        width={64}
                                        height={64}
                                    />
                                    :
                                    <Avatar
                                        src={`/static/images/avatars/${user.avatar}.png`}
                                        sx={{
                                            width: 64,
                                            height: 64,
                                            backgroundColor: '#4db6ac'
                                        }}
                                    />
                                }
                            </Grid>
                            <Grid xs item>
                                {loading
                                    ?
                                    <Skeleton
                                        variant="text"
                                        width="100%"
                                        sx={{
                                            fontSize: 16
                                        }}
                                    />
                                    :
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight={400}
                                    >
                                        {user.nickname}
                                    </Typography>
                                }
                                {loading
                                    ?
                                    <Skeleton
                                        variant="text"
                                        width={80}
                                        sx={{
                                            fontSize: 20
                                        }}
                                    />
                                    :
                                    <Typography variant="h6">
                                        {user.drops} литр
                                    </Typography>
                                }
                                <Button
                                    component={Link}
                                    to="/edit/profile"
                                    variant="contained"
                                    color="inherit"
                                    size="small"
                                    disableElevation
                                    fullWidth
                                    sx={{
                                        marginTop: 1
                                    }}
                                >
                                    Мэдээлэл засварлах
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Query>
                <Grid
                    columnSpacing={2}
                    container
                    sx={{
                        marginTop: 3
                    }}
                >
                    <Grid xs item>
                        <Query
                            fetchPolicy="no-cache"
                            query={TREES}
                            onError={(err: ApolloError) => {
                                //
                            }}
                        >
                            {({ data, loading }: QueryResult) => (
                                <Typography
                                    variant="subtitle1"
                                    textAlign="center"
                                >
                                    {data?.currentUser.trees.count || 0}
                                </Typography>
                            )}
                        </Query>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign="center"
                        >
                            Моднууд
                        </Typography>
                    </Grid>
                    <Grid xs item>
                        <Query
                            fetchPolicy="no-cache"
                            query={CERTIFICATES}
                            onError={(err: ApolloError) => {
                                //
                            }}
                        >
                            {({ data, loading }: QueryResult) => (
                                <Typography
                                    variant="subtitle1"
                                    textAlign="center"
                                >
                                    {data?.currentUserCertificates.count || 0}
                                </Typography>
                            )}
                        </Query>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign="center"
                        >
                            Сертификатууд
                        </Typography>
                    </Grid>
                    <Grid xs item>
                        <Query
                            fetchPolicy="no-cache"
                            query={FRIENDS}
                            onError={(err: ApolloError) => {
                                //
                            }}
                        >
                            {({ data, loading }: QueryResult) => (
                                <Typography
                                    variant="subtitle1"
                                    textAlign="center"
                                >
                                    {data?.friends.count || 0}
                                </Typography>
                            )}
                        </Query>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign="center"
                        >
                            Найзууд
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};