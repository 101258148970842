import { Avatar, Box, Stack, Typography } from '@mui/material';

type FriendDropsProps = {
    avatar: string;
    nickname: string;
};

export const FriendDrops = (props: FriendDropsProps): JSX.Element => {
    const {
        avatar,
        nickname
    } = props;

    return (
        <Box
            sx={{
                width: 'auto',
                minWidth: 160,
                padding: '2px',
                paddingRight: '26px',
                border: '3px solid #fff',
                borderRadius: 100,
                backgroundColor: 'rgba(255, 255, 255, 0.6)'
            }}
        >
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
            >
                <Avatar
                    src={`/static/images/avatars/${avatar}.png`}
                    sx={{
                        width: 40,
                        height: 40,
                        border: '3px solid #fff',
                        backgroundColor: '#4db6ac'
                    }}
                />
                <Typography variant="subtitle2">
                    {nickname}
                </Typography>
            </Stack>
        </Box>
    );
};;