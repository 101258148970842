import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Download } from '@mui/icons-material';
import { Box, Button, Container, Dialog, Stack, SvgIcon, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Certificate } from '../assets/svg';
import { Fetching } from '../components/Fetching';
import { PageHeader } from '../components/PageHeader';
import { TreeAnimation } from '../components/TreeAnimation';
import { changeThemeColor, getCurrentHours } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';

const TREE = gql`
    query Tree($id: String!) {
        tree(id: $id) {
            progress
            certificate {
                number
                fileUrl
                createdAt
            }
            type {
                name
                code
            }
            place {
                name
                location
                partner {
                    name
                    logo
                }
            }
        }
    }
`;

type TreeProps = {
    setColors: (colors: Colors) => void;
    insets: Insets;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setColors
};

export const Tree = connect(mapStateToProps, mapDispatchToProps)((props: TreeProps): JSX.Element => {
    const { id } = useParams();
    const {
        insets,
        setColors
    } = props;

    const nightStartTime = parseInt(process.env.REACT_APP_NIGHT_START_TIME || '19');
    const nightEndTime = parseInt(process.env.REACT_APP_NIGHT_END_TIME || '5');
    const currentHours = getCurrentHours();

    const [
        environment
    ] = useState<'dark' | 'light'>(currentHours >= nightStartTime || currentHours <= nightEndTime ? 'dark' : 'light');
    const [
        open,
        setOpen
    ] = useState(false);

    useEffect(() => {
        switch (environment) {
            case 'dark':
                changeThemeColor('#5660cc');
                setColors({
                    top: '#5660cc',
                    bottom: '#2f739d'
                });
                break;
            case 'light':
                changeThemeColor('#a7d5eb');
                setColors({
                    top: '#a7d5eb',
                    bottom: '#90c7fa'
                });
                break;
        }
    }, []);

    return (
        <Query
            fetchPolicy="no-cache"
            query={TREE}
            variables={{
                id
            }}
            onError={(err: ApolloError) => {
                //
            }}
        >
            {({ data, loading }: QueryResult) => {
                if (loading || data === undefined) {
                    return (
                        <Fetching />
                    );
                }

                const {
                    progress,
                    certificate,
                    type,
                    place
                } = data.tree;

                return (
                    <Box
                        sx={{
                            position: 'fixed',
                            inset: `${insets.top}px 0 ${insets.bottom}px`,
                            display: 'flex'
                        }}
                    >
                        <Container
                            maxWidth="xs"
                            sx={{
                                position: 'relative',
                                flex: 1,
                                overflow: 'hidden'
                            }}
                        >
                            <Dialog
                                maxWidth="xs"
                                open={open}
                                onClose={() => setOpen(false)}
                                fullWidth
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        borderRadius: 0,
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <Box
                                    component="img"
                                    src={certificate.fileUrl}
                                    sx={{
                                        width: '100%'
                                    }}
                                />
                                <Box textAlign="center">
                                    <Button
                                        component="a"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        href={certificate.fileUrl}
                                        download
                                        startIcon={
                                            <Download />
                                        }
                                        sx={{
                                            marginTop: 2
                                        }}
                                    >
                                        Татаж авах
                                    </Button>
                                </Box>
                            </Dialog>
                            <PageHeader
                                color="transparent"
                                dark={false}
                                closeButtonProps={{
                                    component: Link,
                                    to: '/profile'
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    inset: 0,
                                    backgroundImage: `url(/static/images/environments/${environment}/sky.png)`,
                                    backgroundSize: 'cover'
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        inset: 0,
                                        backgroundSize: '200% auto',
                                        backgroundImage: 'url(/static/images/clouds.png)',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center 15%'
                                    }}
                                >
                                    <div className="clouds">
                                        {[1, 2, 3].map(value => (
                                            <img
                                                key={value}
                                                src={`/static/images/cloud-${value}.png`}
                                                className={`cloud cloud-${value}`}
                                            />
                                        ))}
                                    </div>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            inset: 'auto 0px 0px',
                                            paddingTop: '100%',
                                            backgroundSize: '100% auto',
                                            backgroundImage: `url(/static/images/environments/${environment}/ground.png)`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'bottom'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                inset: 'auto auto 52% 50%',
                                                width: '60%',
                                                transform: 'translateX(-50%)'
                                            }}
                                        >
                                            <TreeAnimation
                                                progress={progress}
                                                code={type.code}
                                                id="tree"
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            inset: '88px 24px auto'
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            textAlign="center"
                                        >
                                            {type.name}
                                        </Typography>
                                        <Stack
                                            spacing={2}
                                            sx={{
                                                marginTop: 4
                                            }}
                                        >
                                            <div>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        marginBottom: '2px'
                                                    }}
                                                >
                                                    Байршил:
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {place.name}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        marginBottom: '2px'
                                                    }}
                                                >
                                                    Тарьж буй компани:
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {place.partner.name}
                                                </Typography>
                                            </div>
                                        </Stack>
                                    </Box>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            inset: 'auto 24px 24px'
                                        }}
                                    >
                                        <Stack spacing={1}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                disableElevation
                                                fullWidth
                                                startIcon={
                                                    <SvgIcon
                                                        component={Certificate}
                                                        fontSize="inherit"
                                                        inheritViewBox
                                                    />
                                                }
                                                onClick={() => {
                                                    setOpen(true);
                                                }}
                                            >
                                                Сертификат
                                            </Button>
                                            {/* <Button
                                                color="primary"
                                                size="large"
                                                fullWidth
                                                sx={{
                                                    backgroundColor: '#fff !important'
                                                }}
                                            >
                                                Хуваалцах
                                            </Button> */}
                                        </Stack>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                );
            }}
        </Query>
    );
});
