import { ApolloClient, createHttpLink, from, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { setAccessToken, setUser } from '../redux/auth/actions';
import { store } from '../redux/configureStore';

const apiUrl = process.env.REACT_APP_API_URL as string;

const authHeaders = (token: string | null) => {
    const headers = {
        authorization: ''
    };
    if (token) {
        headers.authorization = 'Bearer '.concat(token);
    }
    return headers;
};

const httpLink = createHttpLink({
    uri: `${apiUrl}/graphql`
});

const wsLink = new GraphQLWsLink(createClient({
    url: `${apiUrl.replace(new RegExp('^(http|https)'), 'ws')}/graphql`,
    connectionParams: () => {
        const state = store.getState();
        const headers = authHeaders(state.auth.accessToken);
        return { headers };
    }
}));

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);

const authLink = setContext((_, { headers }) => {
    const state = store.getState();
    const newHeaders = authHeaders(state.auth.accessToken);
    return {
        headers: {
            ...headers,
            ...newHeaders
        }
    };
});

const errorLink = onError(error => {
    if (error?.graphQLErrors?.some((err: any) => err.extensions.code === 'UNAUTHENTICATED')) {
        store.dispatch(setAccessToken(null));
        store.dispatch(setUser(null));
    }
});

export const apolloClient = new ApolloClient({
    link: from([
        errorLink,
        authLink.concat(splitLink)
    ]),
    cache: new InMemoryCache({
        addTypename: false
    })
});