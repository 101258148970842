import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Box, Button, ButtonGroup, CircularProgress, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { Empty } from '../components/Empty';
import { Friend } from '../components/Friend';
import { withUser } from '../hoc/withUser';

const FRIENDS = gql`
    query Friends(
        $skip: Int
        $take: Int
    ) {
        friendsWithMe(
            skip: $skip
            take: $take
        ) {
            count
            edges {
                id
                avatar
                nickname
                trees {
                    count
                }
            }
        }
    }
`;

type LeaderboardFriendsProps = {
    user: any;
};

export const LeaderboardFriends = withUser<LeaderboardFriendsProps>(({ user }): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();

    const [
        skip,
        setSkip
    ] = useState(0);
    const [
        friends,
        setFriends
    ] = useState<any[]>([]);

    return (
        <Fragment>
            <Box padding={2}>
                <ButtonGroup
                    variant="outlined"
                    color="inherit"
                    fullWidth
                >
                    <Button
                        component={Link}
                        to="/friends"
                        variant="contained"
                        disableElevation
                    >
                        Найзууд
                    </Button>
                    <Button
                        component={Link}
                        to="/friends/leaderboard/global"
                        sx={{
                            borderColor: '#e0e0e0'
                        }}
                    >
                        Бүгд
                    </Button>
                </ButtonGroup>
            </Box>
            <Box
                padding={2}
                paddingTop={0}
            >
                <Query
                    fetchPolicy="no-cache"
                    query={FRIENDS}
                    variables={{
                        skip,
                        take: 20
                    }}
                    onError={(err: ApolloError) => {
                        enqueueSnackbar(err.message, {
                            variant: 'error'
                        });
                    }}
                    onCompleted={(data: any) => {
                        setFriends([
                            ...friends,
                            ...data.friendsWithMe.edges
                        ]);
                    }}
                >
                    {({ data, loading }: QueryResult) => (
                        <Stack spacing={1}>
                            {friends.map(({ id, avatar, nickname, trees }: any, index: number) => (
                                <div key={id}>
                                    <Friend
                                        rank={index + 1}
                                        id={id}
                                        avatar={avatar}
                                        nickname={nickname}
                                        treesCount={trees.count}
                                        isMe={id === user.id}
                                    />
                                </div>
                            ))}
                            {data && (
                                <InView
                                    style={{
                                        height: Number(data.friendsWithMe.count !== friends.length)
                                    }}
                                    onChange={visible => {
                                        if (visible && data.friendsWithMe.count > friends.length) {
                                            setSkip(friends.length);
                                        }
                                    }}
                                />
                            )}
                            {loading && (
                                <Box textAlign="center">
                                    <CircularProgress />
                                </Box>
                            )}
                            {loading === false && friends.length === 0 && (
                                <Empty
                                    top={180}
                                    bottom={58}
                                />
                            )}
                        </Stack>
                    )}
                </Query>
            </Box>
        </Fragment>
    );
});