import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Avatar, Box, Button, CircularProgress, Container, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { Tree } from '../assets/svg';
import { RadioType } from '../components/RadioType';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors } from '../redux/app/reducer';

const TYPES = gql`
    query Types {
        types(
            sortBy: "name"
            sortOrder: "asc"
        ) {
            edges {
                id
                name
                code
                description
                requiredDrops
            }
        }
    }
`;

type ChooseTypeProps = {
    setColors: (colors: Colors) => void;
};

const mapDispatchToProps = {
    setColors
};

export const ChooseType = connect(null, mapDispatchToProps)(withUser<ChooseTypeProps>(({ setColors }): JSX.Element => {
    const [
        typeId,
        setTypeId
    ] = useState('');

    useEffect(() => {
        changeThemeColor('#fff');
        setColors({
            top: '#fff',
            bottom: '#fff'
        });
    }, []);

    return (
        <Query
            fetchPolicy="no-cache"
            query={TYPES}
            onError={(err: ApolloError) => {
                //
            }}
        >
            {({ data, loading }: QueryResult) => (
                <Container maxWidth="xs">
                    <Box padding={2}>
                        <Stack
                            alignItems="center"
                            sx={{
                                marginY: 5
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: 80,
                                    height: 80,
                                    backgroundColor: '#f0f0f0'
                                }}
                            >
                                <SvgIcon
                                    component={Tree}
                                    fontSize="large"
                                    inheritViewBox
                                />
                            </Avatar>
                            <Typography
                                variant="h6"
                                textAlign="center"
                                sx={{
                                    marginTop: 1
                                }}
                            >
                                Модоо сонгоно уу
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                textAlign="center"
                            >
                                Таны сонгосон модны төрлийн дагуу бодитоор тарих болно
                            </Typography>
                        </Stack>
                        {loading && (
                            <Box textAlign="center">
                                <CircularProgress />
                            </Box>
                        )}
                        {data && (
                            <Grid
                                spacing={1}
                                container
                            >
                                {data.types.edges.map(({ id, name, code, description, requiredDrops }: any) => (
                                    <Grid
                                        key={id}
                                        xs={6}
                                        item
                                    >
                                        <RadioType
                                            name={name}
                                            code={code}
                                            description={description}
                                            requiredDrops={requiredDrops}
                                            checked={typeId === id}
                                            onChangeValue={() => {
                                                setTypeId(id);
                                                animateScroll.scrollToBottom();
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        <Button
                            component={Link}
                            to={generatePath('/choose/place/:typeId', { typeId })}
                            disabled={typeId === ''}
                            variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            fullWidth
                            sx={{
                                marginTop: 2
                            }}
                        >
                            Дараах (1/2)
                        </Button>
                    </Box>
                </Container>
            )}
        </Query>
    );
}));
