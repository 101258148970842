import { Circle } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardActionArea, Collapse, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { createUseState } from '../components/UseState';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';
import { dark } from '../theme/muiTheme';

const BooleanState = createUseState<boolean>();

type AboutUsProps = {
    setColors: (colors: Colors) => void;
    insets: Insets;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setColors
};

export const AboutUs = connect(mapStateToProps, mapDispatchToProps)(withUser<AboutUsProps>(({ insets, setColors }): JSX.Element => {
    useEffect(() => {
        changeThemeColor('#13a456');
        setColors({
            top: '#13a456',
            bottom: '#fff'
        });
    }, []);

    return (
        <Container maxWidth="xs">
            <PageHeader
                color="#13a456"
                closeButtonProps={{
                    component: Link,
                    to: '/home'
                }}
            />
            <Box
                sx={{
                    position: 'fixed',
                    inset: `${insets.top + 64}px auto auto`,
                    width: '100%',
                    maxWidth: 444,
                    padding: 2,
                    backgroundColor: '#13a456',
                    zIndex: 999
                }}
            >
                <Stack
                    alignItems="center"
                    spacing={1}
                >
                    <Avatar
                        src="/static/images/pine-tree.png"
                        sx={{
                            width: 96,
                            height: 96
                        }}
                    />
                    <ThemeProvider theme={dark}>
                        <div>
                            <Box textAlign="center">
                                <Box
                                    component="img"
                                    src="/static/images/logo-white.png"
                                    sx={{
                                        marginTop: 2,
                                        width: 80,
                                        height: 'auto'
                                    }}
                                />
                            </Box>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                textAlign="center"
                            >
                                Явц, дэлгэрэнгүй мэдээлэл
                            </Typography>
                        </div>
                    </ThemeProvider>
                </Stack>
                <Grid
                    columnSpacing={1}
                    container
                    sx={{
                        marginTop: 3
                    }}
                >
                    <Grid xs item>
                        <Box color="#fff">
                            <Button
                                component={Link}
                                to="/map"
                                color="inherit"
                                size="large"
                                disableElevation
                                fullWidth
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.25) !important'
                                }}
                            >
                                Сансараас харах
                            </Button>
                        </Box>
                    </Grid>
                    <Grid xs item>
                        <Box color="#fff">
                            <Button
                                color="inherit"
                                size="large"
                                disableElevation
                                fullWidth
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.25) !important'
                                }}
                            >
                                Мэдээ, мэдээлэл
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box
                style={{
                    paddingTop: 328
                }}
            />
            <Box padding={2}>
                <Stack spacing={1}>

                    <BooleanState defaultValue={false}>
                        {(open, setOpen) => (
                            <Fragment>
                                <Card variant="outlined">
                                    <CardActionArea
                                        onClick={() => {
                                            setOpen(!open);
                                        }}
                                        sx={{
                                            backgroundColor: '#f0f0f0'
                                        }}
                                    >
                                        <Box padding={2}>
                                            <Typography variant="subtitle1">
                                                Төв, Баянхангай
                                            </Typography>
                                            <Typography color="text.secondary">
                                                Ногоон Ази сүлжээ ТББ
                                            </Typography>
                                        </Box>
                                    </CardActionArea>
                                    <Collapse in={open}>
                                        <Divider />
                                        <Box padding={2}>
                                            <Typography
                                                variant="subtitle1"
                                                textTransform="uppercase"
                                            >
                                                Санхүүжүүлэгч
                                            </Typography>
                                            <Box
                                                component="img"
                                                src="/static/images/tavan-bogd.png"
                                                sx={{
                                                    marginTop: 2,
                                                    width: 220,
                                                    height: 'auto'
                                                }}
                                            />
                                            <Typography
                                                variant="subtitle1"
                                                textTransform="uppercase"
                                                sx={{
                                                    marginTop: 3,
                                                    marginBottom: 2
                                                }}
                                            >
                                                Төслийн зорилго
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    Монгол орны цөлжилтийг сааруулах зорилгоор Нийгмийн хөгжлийн дэмжлэг /ODA/ бодлогыг хэрэгжүүлж буй жишиг загварыг хөгжүүлэх
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Иргэдийн эрүүл мэндэд сөрөг нөлөө үзүүлж буй хил дамнасан шар шороон шуурга, нарийн тоосонцороос хамгаалах хариу арга хэмжээ авах жишиг загварыг байгуулах
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Дэлхийн уур амьсгалын өөрчлөлтийн хариу арга хэмжээ болон нүүрстөрөгчийн хийн ялгарлыг тэнцвэржүүлэх замаар байгаль орчинд хувь нэмэр оруулах
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                variant="subtitle1"
                                                textTransform="uppercase"
                                                sx={{
                                                    marginTop: 3,
                                                    marginBottom: 2
                                                }}
                                            >
                                                Хэрэгжүүлэх нөхцөл ба шаардлага
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Зүүн хойд Азийн уур амьсгалын өөрчлөлтөд хамгийн их өртсөн бүс нутаг бүхий Монгол орны байгаль орчны асуудлыг шийдвэрлэн ядуурлыг бууруулах
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    БНСУ-ын дотоодын шар шороон шуурга, нарийн тоосонцорыг бууруулах ажлыг Монгол орны цөлжилтөөс урьдчилан сэргийлэх ажилтай уялдуулан уг төслийн хэрэгжүүлэх шаардлагатай
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Цөлжилтөөс урьдчилан сэргийлэх төсөлд аж ахуй нэгжийн оролцоог нэмэгдүүлж буй загварыг хөгжүүлэх (Аж ахуй нэгжүүдийг ESG /Байгаль орчин, Нийгэм, Удирдлага/ бодлоготой холбогдуулах)
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                variant="subtitle1"
                                                textTransform="uppercase"
                                                sx={{
                                                    marginTop: 3,
                                                    marginBottom: 2
                                                }}
                                            >
                                                Төслийн гол агуулга
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Монгол орны цөлжилтөөс урьдчилан сэргийлэх ойжуулалтын дэд бүтцийг бүтээн байгуулах, суулгац  нийлүүлэх, тарилт хийх
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Ойжуулалтын төслийг хэрэгжүүлэх орон нутгийн байгаль орчин болон иргэдэд тохирсон сургалт зэрэг өнөөгийн бодлогыг хэрэгжүүлэх
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Төслийн тогтвортой байдлыг нэмэгдүүлэхийн тулд үр дүн болон арчилгаа мөн төслийн дараах арчилгааны төлөвлөгөөтэй уялдуулан холбох
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                variant="subtitle1"
                                                textTransform="uppercase"
                                                sx={{
                                                    marginTop: 3,
                                                    marginBottom: 2
                                                }}
                                            >
                                                Хүлээгдэж буй үр дүн
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Уур амьсгалыг өөрчлөлттэй тэмцэх Монгол орны цөлжилтөөс урьдчилан сэргийлэх зорилгын "ODA" стратеги үйл ажиллагааны олон давхартгат үр дүнд хүрэх
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Шар шороон шуурганы эх үүсвэрийг арчлан хамгаалснаар шар шороон шуурга, нарийн тоосонцорын нийгэм, эдийн засгийн хохирлоос урьдчилан сэргийлж, иргэдийн эрүүл мэндийг хамгаалах
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Төслийн тогтвортой байдлыг нэмэгдүүлэхийн тулд үр дүн болон арчилгаа мөн төслийн дараах арчилгааны төлөвлөгөөтэй уялдуулан холбох
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                variant="subtitle1"
                                                textTransform="uppercase"
                                                sx={{
                                                    marginTop: 3,
                                                    marginBottom: 2
                                                }}
                                            >
                                                Төсөл хэрэгжүүлэгч
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    БНСУ-ын Ойн аж ахуйг дэмжих агентлаг, Ойн газар
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Ногоон Ази сүлжээ ТББ Сөүл дэх төв, Монгол дахь төлөөлөгчийн газар
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Монгол Улсын Ойжуулалтын талбай, БОАЖЯ, Баянхангай сумын ЗДТ
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Collapse>
                                </Card>
                            </Fragment>
                        )}
                    </BooleanState>
                    <BooleanState defaultValue={false}>
                        {(open, setOpen) => (
                            <Fragment>
                                <Card variant="outlined">
                                    <CardActionArea
                                        onClick={() => {
                                            setOpen(!open);
                                        }}
                                        sx={{
                                            backgroundColor: '#f0f0f0'
                                        }}
                                    >
                                        <Box padding={2}>
                                            <Typography variant="subtitle1">
                                                Булган, Элсэн тасархай
                                            </Typography>
                                            <Typography color="text.secondary">
                                                Монгол Улсын Шинжлэх Ухааны Академи ГАЗАРЗҮЙ, ГЕОЭКОЛОГИЙН ХҮРЭЭЛЭН
                                            </Typography>
                                        </Box>
                                    </CardActionArea>
                                    <Collapse in={open}>
                                        <Divider />
                                        <Box padding={2}>
                                            <Typography
                                                variant="subtitle1"
                                                textTransform="uppercase"
                                            >
                                                Санхүүжүүлэгч
                                            </Typography>
                                            <Box
                                                component="img"
                                                src="/static/images/tavan-bogd.png"
                                                sx={{
                                                    marginTop: 2,
                                                    width: 220,
                                                    height: 'auto'
                                                }}
                                            />
                                            <Typography
                                                variant="subtitle1"
                                                textTransform="uppercase"
                                                sx={{
                                                    marginTop: 3,
                                                    marginBottom: 2
                                                }}
                                            >
                                                Яагаад Элсэнтасархай гэж?
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                Төв аймгийн Эрдэнэсант, Өвөрхангай аймгийн Бүрд, Булган аймгийн Гурванбулаг, Рашаант сумдын нутгийг дамнан орших 80 орчим км урт сунаж тогтсон Жаргалант-Тарнын голын дагуух элсэн манхны талбайн өөрчлөлт, элсжилтийн динамикийг 2000-2017 он хүртэл нийт 17 жилийн хугацаанд тооцож үзэхэд элсэрхэг газар 165.2 км2 талбайгаар нэмэгдсэн байна.
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                textTransform="uppercase"
                                                sx={{
                                                    marginTop: 3,
                                                    marginBottom: 2
                                                }}
                                            >
                                                Цөлжилттэй хэрхэн тэмцэж байгаа вэ?
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Элсэнтасархай дахь суурин судалгааны станцад цөлжилтийн эсрэг судалгааг 2009 оноос тасралтгүй явуулсан.
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Элсний нүүлт, шороон шуургыг бууруулах шилдэг арга бол салхины хамгаалалт байгуулах явдал юм. Төв засмал зам дагуу, 2 га талбайд “Элсний нүүдлээс хамгаалах ойн зурвас байгуулах” ажлыг хийж гүйцэтгэв. Хамгаалалтын ойн зурваст бортогонд тарьж ургуулж бэлтгэсэн хайлас, улиас, бургас, тэхийн шээг зэрэг мод, сөөгийг ойжуулалтад ашигласан бөгөөд, 2020 оны намрын байдлаар улиас 100 %, бургас 99 %, хайлас 95 %–ийн амьдралттай ургаж байна.
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginTop: 1
                                                }}
                                            >
                                                <Box fontSize={8}>
                                                    <Circle
                                                        fontSize="inherit"
                                                        color="action"
                                                        sx={{
                                                            marginTop: '6px'
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Хөдөлгөөнт сул элсийг механик биологийн аргаар бэхжүүлэх туршилтыг ургамалгүй манхан элс бүхий, нийт 10 га талбайд хийж гүйцэтгэсэн. Энэхүү механик хамгаалалт нь 3-4 жил элсийг найдвартай тогтоон барих ба, тус биологийн хамгаалалт буюу мод, сөөг, олон наст тарьж ургуулах ажлуудыг хийж гүйцэтгэх нь илүү үр дүнтэй байна.
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Collapse>
                                </Card>
                            </Fragment>
                        )}
                    </BooleanState>
                </Stack>
            </Box>
        </Container>
    );
}));
