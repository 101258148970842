import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createUseState } from '../components/UseState';
import { CurrentUserFields } from '../fragments/current-user-fields';
import { withUser } from '../hoc/withUser';
import { changeThemeColor, getValidationErrorMessage, parseErrors } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors } from '../redux/app/reducer';
import { setUser } from '../redux/auth/actions';
import { ValidationError } from '../types';

const UseState = createUseState<ValidationError[]>();

const UPDATE_NICKNAME = gql`
    ${CurrentUserFields}
    mutation UpdateNickname($nickname: String) {
        updateNickname(input: {
            nickname: $nickname
        }) {
            ...CurrentUserFields
        }
    }
`;

type CreateNicknameProps = {
    setColors: (colors: Colors) => void;
    setUser: (user: any) => void;
    user: any;
};

const mapDispatchToProps = {
    setColors,
    setUser
};

export const CreateNickname = connect(null, mapDispatchToProps)(withUser<CreateNicknameProps>((props): JSX.Element => {
    const redirect = useNavigate();

    const {
        setColors,
        setUser,
        user
    } = props;

    useEffect(() => {
        changeThemeColor('#fff');
        setColors({
            top: '#fff',
            bottom: '#fff'
        });
    }, []);

    return (
        <UseState defaultValue={[]}>
            {(errors, setErrors) => (
                <Mutation
                    mutation={UPDATE_NICKNAME}
                    variables={{

                    }}
                    onError={(err: ApolloError) => {
                        if (err.message === 'Bad Request Exception') {
                            setErrors(parseErrors(err));
                        }
                    }}
                    onCompleted={({ updateNickname }: any) => {
                        setUser(updateNickname);
                        redirect('/plant/seed');
                    }}
                >
                    {(mutate: MutationFunction, { loading }: MutationResult) => (
                        <Container maxWidth="xs">
                            <Formik
                                initialValues={{
                                    nickname: ''
                                }}
                                onSubmit={values => {
                                    setErrors([]);
                                    mutate({
                                        variables: values
                                    });
                                }}
                            >
                                {({ values, handleChange, handleSubmit }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        autoComplete="off"
                                        noValidate
                                    >
                                        <Box padding={2}>
                                            <Stack
                                                alignItems="center"
                                                spacing={1}
                                                sx={{
                                                    marginBottom: 3
                                                }}
                                            >
                                                <Avatar
                                                    src={`/static/images/avatars/${user.avatar}.png`}
                                                    sx={{
                                                        width: 96,
                                                        height: 96,
                                                        backgroundColor: '#4db6ac'
                                                    }}
                                                />
                                                <Typography
                                                    variant="h6"
                                                    textAlign="center"
                                                >
                                                    Нэрээ оруулна уу
                                                </Typography>
                                            </Stack>
                                            <TextField
                                                variant="outlined"
                                                label="Нэр"
                                                name="nickname"
                                                value={values.nickname}
                                                onChange={handleChange}
                                                error={errors.some(({ property }) => property === 'nickname')}
                                                helperText={getValidationErrorMessage(errors, 'nickname')}
                                                fullWidth
                                            />
                                            <Stack
                                                spacing={1}
                                                sx={{
                                                    marginTop: 2
                                                }}
                                            >
                                                <LoadingButton
                                                    loading={loading}
                                                    disabled={values.nickname === ''}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    type="submit"
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    За эхэлцгээе
                                                </LoadingButton>
                                                <Button
                                                    component={Link}
                                                    to="/create/avatar"
                                                    color="primary"
                                                    size="large"
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    Буцах
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Container>
                    )}
                </Mutation>
            )}
        </UseState>
    );
}));
