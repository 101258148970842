import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Box, Chip, Container, Tab, TabProps, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Bell } from '../assets/svg';
import { PageHeader } from '../components/PageHeader';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';

const UNREAD_NOTIFICATIONS = gql`
    query Notifications {
        unreadNotifications {
            count
        }
    }
`;

type NotificationsParentProps = {
    setColors: (colors: Colors) => void;
    insets: Insets;
};

type StyledChipProps = {
    label: string;
};

type StyledTabProps = TabProps & {
    component?: React.ElementType;
    to?: string;
};

const StyledTabs = styled(Tabs)({
    backgroundColor: '#1d969d',
    '& .MuiTabs-indicator': {
        height: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.38)'
    }
});

const StyledTab = styled(Tab)<StyledTabProps>({
    color: 'rgba(255, 255, 255, 0.6)',
    '&.Mui-selected': {
        color: '#fff'
    }
});

const StyledChip = styled((props: StyledChipProps) => (
    <Chip {...props} size="small" component="span" />
))({
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    color: '#fff'
});

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setColors
};

export const NotificationsParent = connect(mapStateToProps, mapDispatchToProps)(withUser<NotificationsParentProps>((props): JSX.Element => {
    const { pathname } = useLocation();
    const {
        setColors,
        insets
    } = props;

    const [
        tab,
        setTab
    ] = useState(pathname === '/notifications' ? 0 : 1);
    const [
        count,
        setCount
    ] = useState(0);

    useEffect(() => {
        changeThemeColor('#1d969d');
        setColors({
            top: '#1d969d',
            bottom: '#fff'
        });
    }, []);

    return (
        <Container maxWidth="xs">
            <Query
                fetchPolicy="no-cache"
                query={UNREAD_NOTIFICATIONS}
                onError={(err: ApolloError) => {
                    //
                }}
                onCompleted={(data: any) => {
                    setCount(data.unreadNotifications.count);
                }}
            >
                {({ data }: QueryResult) => (
                    <PageHeader
                        svgIcon={Bell}
                        title="Мэдэгдэл"
                        color="#1d969d"
                        closeButtonProps={{
                            component: Link,
                            to: '/home'
                        }}
                        description={
                            <span>
                                Танд шинэ <StyledChip label={`${count} мэдэгдэл`} /> байна
                            </span>
                        }
                    />
                )}
            </Query>
            <StyledTabs
                value={tab}
                onChange={(_, value) => setTab(value)}
                sx={{
                    position: 'fixed',
                    inset: `${80 + insets.top}px auto auto`,
                    width: '100%',
                    maxWidth: 444,
                    zIndex: 999
                }}
            >
                <StyledTab
                    component={Link}
                    label="Сүүлд ирсэн"
                    to="/notifications"
                />
                <StyledTab
                    component={Link}
                    label="Усны түүх"
                    to="/notifications/transactions"
                />
            </StyledTabs>
            <Box
                style={{
                    paddingTop: 128
                }}
            />
            <Outlet />
        </Container>
    );
}));
