import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query, Subscription } from '@apollo/client/react/components';
import { Box, BoxProps, Stack } from '@mui/material';
import { useState } from 'react';
import { connect } from 'react-redux';
import { setUserDrops } from '../redux/auth/actions';
import { RootState } from '../redux/configureStore';
import { Drop } from './Drop';

const UNCLAIMED_REWARDS = gql`
    query UnclaimedRewards {
        unclaimedRewards(
                sortBy: "expireAt"
                sortOrder: "asc"
        ) {
            edges {
                id
                reason
                drops
                expireAt
                createdAt
            }
        }
    }
`;

const ON_REWARD_ADDED = gql`
    subscription OnRewardAdded {
        rewardAdded {
            id
            reason
            drops
            expireAt
            createdAt
        }
    }
`;

const ON_REWARD_CLAIMED = gql`
    subscription OnRewardClaimed($userId: String!) {
        rewardClaimed(userId: $userId) {
            id
        }
    }
`;

type CollectableDropsProps = BoxProps & {
    setUserDrops: (drops: number, currentTreeDrops?: number) => void;
    user: any;
};

const mapStateToProps = ({ auth }: RootState) => ({
    user: auth.user
});

const mapDispatchToProps = {
    setUserDrops
};

export const CollectableDrops = connect(mapStateToProps, mapDispatchToProps)(({ user, setUserDrops, ...props }: CollectableDropsProps) => {
    const [
        rewards,
        setRewards
    ] = useState<any>([]);

    return (
        <Box {...props}>
            <Subscription
                subscription={ON_REWARD_ADDED}
                onSubscriptionData={({ subscriptionData }: any) => {
                    setRewards([...rewards, subscriptionData.data.rewardAdded]);
                }}
            />
            <Subscription
                subscription={ON_REWARD_CLAIMED}
                variables={{
                    userId: user.id
                }}
                onSubscriptionData={({ subscriptionData }: any) => {
                    setRewards(rewards.filter(({ id }: any) => id !== subscriptionData.data.rewardClaimed.id));
                }}
            />
            <Query
                fetchPolicy="no-cache"
                query={UNCLAIMED_REWARDS}
                variables={{

                }}
                onError={(err: ApolloError) => {
                    //
                }}
                onCompleted={({ unclaimedRewards }: any) => {
                    setRewards(unclaimedRewards.edges);
                }}
            >
                {({ loading }: QueryResult) => (
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            padding: 4
                        }}
                    >
                        {rewards.slice(0, 1).map(({ id, reason, drops, expireAt, createdAt }: any, index: number) => (
                            <div key={id}>
                                <Drop
                                    id={id}
                                    drops={drops}
                                    reason={reason}
                                    expireAt={expireAt}
                                    createdAt={createdAt}
                                    onExpire={() => {
                                        setRewards(rewards.filter((reward: any) => reward.id !== id));
                                    }}
                                    onError={err => {
                                        setRewards(rewards.filter((reward: any) => reward.id !== id));
                                    }}
                                    onClaim={drops => {
                                        setUserDrops(drops);
                                        setRewards(rewards.filter((reward: any) => reward.id !== id));
                                    }}
                                />
                            </div>
                        ))}
                    </Stack>
                )}
            </Query>
        </Box>
    );
});