import { Avatar, Badge, SvgIcon } from '@mui/material';
import { Thief } from '../assets/svg';

type StealChargesProps = {
    charges: number;
};

export const StealCharges = ({ charges }: StealChargesProps): JSX.Element => {
    return (
        <Avatar
            sx={{
                width: 50,
                height: 50,
                border: '3px solid #fff',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                overflow: 'visible'
            }}
        >
            <Badge
                badgeContent={charges}
                showZero
            >
                <SvgIcon
                    component={Thief}
                    inheritViewBox
                />
            </Badge>
        </Avatar>
    );
};;