import { default as Animation, PartialLottieComponentProps } from 'lottie-react';
import { useEffect, useState } from 'react';
import { generateTreeJson } from '../lib/helpers';

type TreeAnimationProps = PartialLottieComponentProps & {
    code: 'aspen' | 'elm' | 'pine' | 'willow' | 'sea-buckthorn';
    progress: number;
    onChange?: () => void;
};

export const TreeAnimation = (props: TreeAnimationProps): JSX.Element => {
    const {
        code,
        progress,
        onChange,
        ...other
    } = props;

    const [
        data,
        setData
    ] = useState<React.ReactNode | null>(null);
    const [
        path,
        setPath
    ] = useState(generateTreeJson(code, progress));

    useEffect(() => {
        setPath(generateTreeJson(code, progress));
    }, [progress]);

    useEffect(() => {
        import(`../assets/lottie/${path}`).then(json => {
            setData(json);
            onChange?.call(this);
        });
    }, [path]);

    return (
        <Animation {...other} animationData={data} />
    );
};