import { ApolloError, gql, MutationFunction } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Avatar, Box, CardActionArea, LinearProgress, SvgIcon, Typography } from '@mui/material';
import { default as Animation } from 'lottie-react';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useRef, useState } from 'react';
import { default as uniqid } from 'uniqid';
import { useInterval } from 'usehooks-ts';
import { Confetti } from '../assets/lottie';
import { Gift, Stopwatch } from '../assets/svg';
import { animateCSS } from '../lib/helpers';

const CLAIM_REWARD = gql`
    mutation ClaimReward($id: String) {
        claimReward(input: {
            id: $id
        })
    }
`;

type DropProps = {
    id: string;
    drops: number;
    reason: 'walk' | 'system' | 'gift';
    expireAt?: number;
    createdAt: number;
    onExpire: () => void;
    onError: (err: ApolloError) => void;
    onClaim: (drops: number) => void;
};

export const Drop = (props: DropProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const lottie = useRef<any>();

    const {
        id,
        drops,
        reason,
        expireAt,
        createdAt,
        onExpire,
        onError,
        onClaim
    } = props;

    const [
        selector
    ] = useState(uniqid());
    const [
        popped,
        setPopped
    ] = useState(false);
    const [
        progress,
        setProgress
    ] = useState(100);
    const [
        symbol,
        setSymbol
    ] = useState('+');

    useEffect(() => {
        popped && lottie.current?.goToAndPlay(0, true);
    }, [popped]);

    useInterval(() => {
        calculateProgress();
        checkExpires();
    }, 1000);

    const calculateProgress = () => {
        if (expireAt) {
            setProgress((expireAt - Date.now()) / (expireAt - createdAt) * 100);
        }
    };

    const checkExpires = () => {
        if (expireAt && expireAt < Date.now()) {
            setPopped(true);
            setSymbol('-');
            setTimeout(() => animateCSS(`#${selector}`, 'fadeOutDown').then(() => {
                onExpire();
            }), 2000);
        };
    };

    return (
        <Mutation
            mutation={CLAIM_REWARD}
            variables={{
                id
            }}
            onError={(err: ApolloError) => {
                setTimeout(() => animateCSS(`#${selector}`, 'fadeOutUp').then(() => {
                    enqueueSnackbar(err.message, {
                        variant: 'error'
                    });
                    onError(err);
                }), 2000);
            }}
            onCompleted={() => {
                setTimeout(() => animateCSS(`#${selector}`, 'fadeOutUp').then(() => {
                    onClaim(drops);
                }), 2000);
            }}
        >
            {(mutate: MutationFunction) => (
                <Box
                    className="animate__animated animate__zoomIn animate__faster"
                    sx={{
                        position: 'relative',
                        width: 76,
                        height: 76
                    }}
                >
                    <Animation
                        lottieRef={lottie}
                        autoplay={false}
                        loop={false}
                        animationData={Confetti}
                        style={{
                            position: 'absolute',
                            inset: 0
                        }}
                    />
                    {popped === false && (
                        <CardActionArea
                            className="animate__animated animate__pulse animate__infinite animate__slow"
                            onClick={() => {
                                setPopped(true);
                                mutate();
                            }}
                            sx={{
                                position: 'absolute',
                                inset: 0,
                                borderRadius: '50%'
                            }}
                        >
                            <Avatar
                                src={`/static/images/bubble-${reason === 'walk' ? 1 : 2}.png`}
                                sx={{
                                    width: 76,
                                    height: 76,
                                    padding: '2px',
                                    border: '4px solid #fff',
                                    backgroundColor: 'rgba(255, 255, 255, 0.6)'
                                }}
                            />
                            <Typography
                                variant="h6"
                                textAlign="center"
                                sx={{
                                    position: 'absolute',
                                    inset: '50% auto auto 50%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                            >
                                {drops}л
                            </Typography>
                            {reason === 'walk' && (
                                <Avatar
                                    src="/static/images/walking.png"
                                    sx={{
                                        position: 'absolute',
                                        inset: '-8px auto auto -8px',
                                        width: 32,
                                        height: 32,
                                        border: '3px solid #fff'
                                    }}
                                />
                            )}
                            {reason === 'gift' && (
                                <Avatar
                                    sx={{
                                        position: 'absolute',
                                        inset: '-8px auto auto -8px',
                                        width: 32,
                                        height: 32,
                                        border: '3px solid #fff',
                                        backgroundColor: '#f9f4cc'
                                    }}
                                >
                                    <SvgIcon
                                        component={Gift}
                                        fontSize="small"
                                        inheritViewBox
                                    />
                                </Avatar>
                            )}
                            {expireAt && (
                                <Fragment>
                                    <LinearProgress
                                        variant="determinate"
                                        value={progress}
                                        sx={{
                                            position: 'absolute',
                                            inset: 'auto 25% -6px 25%',
                                            height: 6
                                        }}
                                    />
                                    <SvgIcon
                                        component={Stopwatch}
                                        inheritViewBox
                                        sx={{
                                            position: 'absolute',
                                            inset: 'auto auto -15px 0'
                                        }}
                                    />
                                </Fragment>
                            )}
                        </CardActionArea>
                    )}
                    {popped && (
                        <Box
                            sx={{
                                position: 'absolute',
                                inset: '50% auto auto 50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            <div className="animate__animated animate__zoomIn animate__faster">
                                <Box
                                    className="animate__animated"
                                    id={selector}
                                    sx={{
                                        '&:not(.animate__animated)': {
                                            opacity: 0
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        textAlign="center"
                                    >
                                        {symbol}{drops}л
                                    </Typography>
                                </Box>
                            </div>
                        </Box>
                    )}
                </Box>
            )}
        </Mutation>
    );
};