import { Box } from '@mui/material';
import { default as Animation } from 'lottie-react';
import { WalkingPothos } from '../assets/lottie';

export const Fetching = (): JSX.Element => {
    return (
        <Box
            sx={{
                position: 'fixed',
                inset: 0,
                zIndex: 999,
                backgroundColor: '#fff'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    inset: '50% auto auto 50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Animation
                    animationData={WalkingPothos}
                    style={{
                        width: 200
                    }}
                />
            </Box>
        </Box>
    );
};