import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, set } from 'lodash';

export type AuthState = {
    accessToken: string | null;
    user: any;
};

const initialState: AuthState = {
    accessToken: null,
    user: null
};

const handlers = {
    SET_ACCESS_TOKEN: (state: AuthState, { payload }: PayloadAction<{ accessToken: string | null; }>) => ({
        ...state,
        ...payload
    }),
    SET_USER: (state: AuthState, { payload }: PayloadAction<{ user: any; }>) => ({
        ...state,
        ...payload
    }),
    SET_USER_DROPS: (state: AuthState, { payload }: PayloadAction<{ drops: number; currentTreeDrops: number; }>) => {
        const newState = cloneDeep(state);
        set(newState, 'user.drops', state.user.drops + payload.drops);
        set(newState, 'user.currentTree.drops', state.user.currentTree.drops + payload.currentTreeDrops);
        return newState;
    }
};

export const auth = createReducer(
    initialState,
    handlers
);