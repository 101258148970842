import { useEffect, useRef, useState } from 'react';

type GoogleMapProps = {
    center: google.maps.LatLngLiteral;
    zoom: number;
    markers: google.maps.LatLngLiteral[];
};

export function GoogleMap(props: GoogleMapProps) {
    const ref = useRef<HTMLDivElement>(null);
    const {
        center,
        zoom,
        markers
    } = props;

    const [
        map,
        setMap
    ] = useState<google.maps.Map>();
    const [
        currentMarkers,
        setCurrentMarkers
    ] = useState<google.maps.Marker[]>([]);

    useEffect(() => {
        if (ref?.current) {
            setMap(new window.google.maps.Map(ref.current, {
                center,
                zoom,
                mapTypeId: 'hybrid'
            }));
        }
    }, []);

    useEffect(() => {
        currentMarkers.map(marker => {
            marker.setMap(null);
        });
        setCurrentMarkers(markers.map(marker => new window.google.maps.Marker({
            map,
            position: marker
        })));
    }, [
        markers
    ]);

    return (
        <div id="map" ref={ref} />
    );
}