import { Box, Button, Card, CardActionArea, Chip, Dialog, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Info } from '../assets/svg';
import { TreeAnimation } from './TreeAnimation';
import { createUseState } from './UseState';

type RadioTypeProps = {
    name: string;
    code: 'aspen' | 'elm' | 'pine' | 'willow' | 'sea-buckthorn';
    description: string;
    requiredDrops: number;
    checked: boolean;
    onChangeValue: (checked: boolean) => void;
};

const BooleanState = createUseState<boolean>();

export const RadioType = (props: RadioTypeProps): JSX.Element => {
    const {
        name,
        code,
        description,
        requiredDrops,
        checked,
        onChangeValue
    } = props;

    return (
        <BooleanState defaultValue={false}>
            {(open, setOpen) => (
                <Fragment>
                    <Dialog
                        maxWidth="xs"
                        open={open}
                        onClose={() => setOpen(false)}
                        fullWidth
                    >
                        <Box padding={2}>
                            <Stack alignItems="center">
                                <TreeAnimation
                                    progress={100}
                                    code={code}
                                    style={{
                                        width: '80%'
                                    }}
                                />
                            </Stack>
                            <Typography
                                variant="h6"
                                textAlign="center"
                                sx={{
                                    marginTop: 2,
                                    marginBottom: 1
                                }}
                            >
                                {name}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                textAlign="center"
                            >
                                {description}
                            </Typography>
                            <Box textAlign="center">
                                <Button
                                    size="large"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                    sx={{
                                        marginTop: 2
                                    }}
                                >
                                    Хаах
                                </Button>
                            </Box>
                        </Box>
                    </Dialog>
                    <Card
                        variant="outlined"
                        sx={{
                            position: 'relative',
                            borderColor: checked
                                ? 'primary.main'
                                : 'divider',
                            backgroundColor: checked
                                ? '#0c4da21a'
                                : '#fff'
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                inset: '4px 4px auto auto',
                                zIndex: 1,
                                color: 'text.disabled'
                            }}
                        >
                            <IconButton
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                <SvgIcon
                                    component={Info}
                                    inheritViewBox
                                />
                            </IconButton>
                        </Box>
                        <CardActionArea
                            onClick={() => {
                                onChangeValue(true);
                            }}
                        >
                            <Box
                                sx={{
                                    padding: '12px 16px'
                                }}
                            >
                                <Stack alignItems="center">
                                    <TreeAnimation
                                        progress={100}
                                        code={code}
                                        style={{
                                            width: '80%'
                                        }}
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        textAlign="center"
                                        sx={{
                                            marginTop: 1
                                        }}
                                    >
                                        {name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        textAlign="center"
                                        sx={{
                                            marginTop: 1,
                                            fontSize: 12,
                                            fontStyle: 'italic'
                                        }}
                                    >
                                        Шаардлагатай усны хэмжээ
                                    </Typography>
                                    <Chip
                                        variant="outlined"
                                        size="small"
                                        color="info"
                                        label={`${requiredDrops} литр ус`}
                                        sx={{
                                            marginTop: '4px',
                                            border: 'none',
                                            borderRadius: '4px',
                                            backgroundColor: '#21b8e81a'
                                        }}
                                    />
                                </Stack>
                            </Box>
                        </CardActionArea>
                    </Card>
                </Fragment>
            )}
        </BooleanState>
    );
};