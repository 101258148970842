export const setAccessToken = (accessToken: string | null) => ({
    type: 'SET_ACCESS_TOKEN',
    payload: {
        accessToken
    }
});

export const setUser = (user: any) => ({
    type: 'SET_USER',
    payload: {
        user
    }
});

export const setUserDrops = (drops: number, currentTreeDrops: number = 0) => ({
    type: 'SET_USER_DROPS',
    payload: {
        drops,
        currentTreeDrops
    }
});