import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Card, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { UserAdd } from '../assets/svg';

const SEND_FRIEND_REQUEST = gql`
    mutation SendFriendRequest($userId: String) {
        sendFriendRequest(input: {
            userId: $userId
        })
    }
`;

type FriendProps = {
    id: string;
    avatar: string;
    nickname: string;
    treesCount: number;
};

export const Stranger = (props: FriendProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        id,
        avatar,
        nickname,
        treesCount
    } = props;

    return (
        <Card variant="outlined">
            <Box
                paddingY={1}
                paddingX={2}
            >
                <Grid
                    columnSpacing={1}
                    alignItems="center"
                    container
                >
                    <Grid xs item>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing="12px"
                        >
                            <Avatar
                                src={`/static/images/avatars/${avatar}.png`}
                                sx={{
                                    width: 48,
                                    height: 48,
                                    backgroundColor: '#f0f0f0'
                                }}
                            />
                            <div>
                                <Typography variant="subtitle1">
                                    {nickname}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.disabled"
                                >
                                    {treesCount} мод
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Mutation
                            mutation={SEND_FRIEND_REQUEST}
                            variables={{
                                userId: id
                            }}
                            onError={(err: ApolloError) => {
                                enqueueSnackbar(err.message === 'Bad Request Exception' ? 'Найзын хүсэлт илгээсэн байна' : err.message, {
                                    variant: 'error'
                                });
                            }}
                            onCompleted={() => {
                                enqueueSnackbar('Найзын хүсэлт илгээлээ.', {
                                    variant: 'success'
                                });
                            }}
                        >
                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                <LoadingButton
                                    loading={loading}
                                    variant="contained"
                                    color="inherit"
                                    disableElevation
                                    onClick={() => {
                                        mutate();
                                    }}
                                    sx={{
                                        minWidth: 0
                                    }}
                                >
                                    <SvgIcon
                                        component={UserAdd}
                                        inheritViewBox
                                    />
                                </LoadingButton>
                            )}
                        </Mutation>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};