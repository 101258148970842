import { Avatar, Card, CardActionArea, Grid, Stack, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import { generatePath, Link } from 'react-router-dom';
import { Bronze, Gift, Gold, Silver } from '../assets/svg';
import { dark, light } from '../theme/muiTheme';

type FriendProps = {
    id: string;
    avatar: string;
    nickname: string;
    rank: number;
    treesCount: number;
    isMe?: boolean;
};

export const Friend = (props: FriendProps): JSX.Element => {
    const {
        isMe = false,
        id,
        avatar,
        nickname,
        rank,
        treesCount
    } = props;

    return (
        <ThemeProvider theme={isMe ? dark : light}>
            <Card
                variant="outlined"
                sx={{
                    backgroundColor: isMe
                        ? 'primary.main'
                        : 'background.paper'
                }}
            >
                <CardActionArea
                    component={Link}
                    to={generatePath('/send/gift/:userId', { userId: id })}
                    sx={{
                        padding: '8px 16px',
                        borderRadius: 1
                    }}
                >
                    <Grid
                        columnSpacing={1}
                        alignItems="center"
                        container
                    >
                        <Grid xs item>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing="12px"
                            >
                                {rank === 1 && (
                                    <SvgIcon
                                        component={Gold}
                                        inheritViewBox
                                    />
                                )}
                                {rank === 2 && (
                                    <SvgIcon
                                        component={Silver}
                                        inheritViewBox
                                    />
                                )}
                                {rank === 3 && (
                                    <SvgIcon
                                        component={Bronze}
                                        inheritViewBox
                                    />
                                )}
                                {[1, 2, 3].find(value => value === rank) === undefined && (
                                    <Typography
                                        variant="subtitle1"
                                        textAlign="center"
                                        fontWeight={700}
                                        sx={{
                                            width: 24
                                        }}
                                    >
                                        {rank}
                                    </Typography>
                                )}
                                <Avatar
                                    src={`/static/images/avatars/${avatar}.png`}
                                    sx={{
                                        width: 48,
                                        height: 48,
                                        backgroundColor: '#f0f0f0'
                                    }}
                                />
                                <div>
                                    <Typography variant="subtitle1">
                                        {nickname}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.disabled"
                                    >
                                        {treesCount} мод
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                        {isMe === false && (
                            <Grid item>
                                <SvgIcon
                                    component={Gift}
                                    inheritViewBox
                                    sx={{
                                        display: 'block'
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </CardActionArea>
            </Card>
        </ThemeProvider>
    );
};