import { Circle } from '@mui/icons-material';
import { Button, CardActionArea, Grid, Stack } from '@mui/material';

type IntroDotsProps = {
    step: number;
    onStart: () => void;
    onChangeStep: (step: number) => void;
};

export const IntroDots = (props: IntroDotsProps): JSX.Element => {
    const {
        step,
        onStart,
        onChangeStep
    } = props;

    return (
        <Grid
            alignItems="center"
            container
        >
            <Grid xs item>
                <Stack
                    justifyContent="center"
                    direction="row"
                    spacing={1}
                >
                    {[0, 1, 2, 3].map(num => (
                        <CardActionArea
                            key={num}
                            onClick={() => {
                                onChangeStep(num);
                            }}
                            sx={{
                                width: 'auto',
                                borderRadius: '50%'
                            }}
                        >
                            <Circle
                                key={num}
                                sx={{
                                    display: 'block',
                                    fontSize: 12,
                                    color: num === step
                                        ? 'primary.main'
                                        : 'divider'
                                }}
                            />
                        </CardActionArea>
                    ))}
                </Stack>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disableElevation
                    onClick={() => {
                        if (step === 3) {
                            onStart();
                        } else {
                            onChangeStep(step + 1);
                        }
                    }}
                    sx={{
                        minWidth: 110
                    }}
                >
                    {step === 3 ? 'Эхлэх' : 'Дараах'}
                </Button>
            </Grid>
        </Grid>
    );
};