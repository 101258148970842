import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Avatar, Card, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

const ACCEPT_FRIEND_REQUEST = gql`
    mutation AcceptFriendRequest($userId: String) {
        acceptFriendRequest(input: {
            userId: $userId
        })
    }
`;

const DECLINE_FRIEND_REQUEST = gql`
    mutation DeclineFriendRequest($userId: String) {
        declineFriendRequest(input: {
            userId: $userId
        })
    }
`;

type FriendRequestProps = {
    onRespond: (id: string) => void;
    id: string;
    avatar: string;
    nickname: string;
    treesCount: number;
};

export const FriendRequest = (props: FriendRequestProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        onRespond,
        id,
        avatar,
        nickname,
        treesCount
    } = props;

    return (
        <Card
            variant="outlined"
            sx={{
                padding: '8px 16px',
                borderRadius: 1,
                backgroundColor: 'background.paper'
            }}
        >
            <Stack
                direction="row"
                spacing="12px"
            >
                <Avatar
                    src={`/static/images/avatars/${avatar}.png`}
                    sx={{
                        width: 48,
                        height: 48,
                        backgroundColor: '#f0f0f0'
                    }}
                />
                <div>
                    <Typography variant="subtitle1">
                        {nickname}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.disabled"
                    >
                        {treesCount} мод
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            marginTop: 1
                        }}
                    >
                        <Mutation
                            mutation={ACCEPT_FRIEND_REQUEST}
                            variables={{
                                userId: id
                            }}
                            onError={(err: ApolloError) => {
                                enqueueSnackbar(err.message, {
                                    variant: 'error'
                                });
                            }}
                            onCompleted={() => {
                                onRespond(id);
                            }}
                        >
                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                <LoadingButton
                                    loading={loading}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disableElevation
                                    onClick={() => {
                                        mutate();
                                    }}
                                >
                                    Зөвшөөрөх
                                </LoadingButton>
                            )}
                        </Mutation>
                        <Mutation
                            mutation={DECLINE_FRIEND_REQUEST}
                            variables={{
                                userId: id
                            }}
                            onError={(err: ApolloError) => {
                                enqueueSnackbar(err.message, {
                                    variant: 'error'
                                });
                            }}
                            onCompleted={() => {
                                onRespond(id);
                            }}
                        >
                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                <LoadingButton
                                    loading={loading}
                                    variant="contained"
                                    color="inherit"
                                    size="small"
                                    disableElevation
                                    onClick={() => {
                                        mutate();
                                    }}
                                >
                                    Устгах
                                </LoadingButton>
                            )}
                        </Mutation>
                    </Stack>
                </div>
            </Stack>
        </Card>
    );
};