import { ApolloError, gql, QueryResult, useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Add, Place } from '@mui/icons-material';
import { Box, Button, ButtonBase, Card, Container, Dialog, Grid, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { default as Animation } from 'lottie-react';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import { Watering } from '../assets/lottie';
import { QuestionMark } from '../assets/svg';
import { Anchor } from '../components/Anchor';
import { CollectableDrops } from '../components/CollectableDrops';
import { Confetti } from '../components/Confetti';
import { HorizontalProgress } from '../components/HorizontalProgress';
import { MainMenu } from '../components/MainMenu';
import { TreeAnimation } from '../components/TreeAnimation';
import { UserDrops } from '../components/UserDrops';
import { WelcomeMessage } from '../components/WelcomeMessage';
import { CurrentUserFields } from '../fragments/current-user-fields';
import { withUser } from '../hoc/withUser';
import { useHold } from '../hooks/useHold';
import { useQuery } from '../hooks/useQuery';
import { animateCSS, changeThemeColor, getCurrentHours } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { setUser, setUserDrops } from '../redux/auth/actions';
import { RootState } from '../redux/configureStore';

const CURRENT_USER = gql`
    ${CurrentUserFields}
    query CurrentUser {
        currentUser {
            ...CurrentUserFields
        }
    }
`;

const CREATE_WATERING = gql`
    mutation CreateWatering(
        $drops: Int
        $treeId: String
    ) {
        createWatering(input: {
            drops: $drops
            treeId: $treeId
        }) {
            id
        }
    }
`;

type HomeProps = {
    setUserDrops: (drops: number, currentTreeDrops?: number) => void;
    setColors: (colors: Colors) => void;
    setUser: (user: any) => void;
    insets: Insets;
    user: any;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setUserDrops,
    setColors,
    setUser
};

const INITIAL_TIMER = 500;

export const Home = connect(mapStateToProps, mapDispatchToProps)(withUser<HomeProps>((props): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        setUserDrops,
        setColors,
        setUser,
        insets,
        user
    } = props;

    const lottie = useRef<any>();
    const query = useQuery();
    const nightStartTime = parseInt(process.env.REACT_APP_NIGHT_START_TIME || '19');
    const nightEndTime = parseInt(process.env.REACT_APP_NIGHT_END_TIME || '5');
    const currentHours = getCurrentHours();

    const [mutate] = useMutation(CREATE_WATERING, {
        onError: (err: ApolloError) => {
            enqueueSnackbar(err.message, {
                variant: 'error'
            });
        },
        onCompleted: (data: any) => {
            if (user.currentTree.drops === user.currentTree.requiredDrops) {
                setAlert(true);
            }
        }
    });

    const [
        environment
    ] = useState<'dark' | 'light'>(currentHours >= nightStartTime || currentHours <= nightEndTime ? 'dark' : 'light');
    const [
        timer,
        setTimer
    ] = useState<number | null>(null);
    const [
        disabled,
        setDisabled
    ] = useState(false);
    const [
        userBalance,
        setUserBalance
    ] = useState(0);
    const [
        open,
        setOpen
    ] = useState(false);
    const [
        alert,
        setAlert
    ] = useState(false);
    const [
        holding,
        setHolding
    ] = useState(false);

    const progress = user.currentTree.drops / user.currentTree.requiredDrops * 100;

    useEffect(() => {
        window.ReactNativeWebView?.postMessage(JSON.stringify({
            type: 'HOME'
        }));
        window.addEventListener('contextmenu', function (e) {
            e.preventDefault();
        });
    }, []);

    useEffect(() => {
        switch (environment) {
            case 'dark':
                changeThemeColor('#5660cc');
                setColors({
                    top: '#5660cc',
                    bottom: '#2f739d'
                });
                break;
            case 'light':
                changeThemeColor('#a7d5eb');
                setColors({
                    top: '#a7d5eb',
                    bottom: '#90c7fa'
                });
                break;
        }
    }, []);

    useInterval(() => {
        if (user.drops <= 0 || user.currentTree.drops >= user.currentTree.requiredDrops) {
            return onHoldEnd();
        }
        if (timer && timer > 50) {
            setTimer(timer - 10);
        }
        waterTree();
    }, timer);

    const waterTree = () => {
        if (user.drops <= 0 || user.currentTree.drops >= user.currentTree.requiredDrops) {
            return;
        }
        setUserDrops(-1, 1);
    };

    const onHoldStart = () => {
        lottie.current?.playSegments([
            [0, 12],
            [13, 18]
        ], true);
        setUserBalance(user.drops);
        setHolding(true);
        setTimer(INITIAL_TIMER);
        setDisabled(true);
    };

    const onHoldEnd = () => {
        if (holding) {
            lottie.current?.playSegments([
                [35, Number.MAX_SAFE_INTEGER]
            ]);
            setHolding(false);
            setTimer(null);
            window.setTimeout(() => {
                setDisabled(false);
            }, 500);
            const drops = userBalance - user.drops;
            if (drops) {
                mutate({
                    onError: () => {
                        setUserDrops(drops, -drops);
                    },
                    variables: {
                        drops,
                        treeId: user.currentTree.id
                    }
                });
            }
        }
    };

    const onClick = () => {
        lottie.current?.playSegments([
            [0, 12],
            [35, Number.MAX_SAFE_INTEGER]
        ], true);
        setDisabled(true);
        waterTree();
        mutate({
            onError: () => {
                setUserDrops(1, -1);
            },
            variables: {
                drops: 1,
                treeId: user.currentTree.id
            }
        });
        window.setTimeout(() => {
            setDisabled(false);
        }, 900);
    };

    const {
        onTouchStart,
        onTouchEnd
    } = useHold({
        onHoldStart,
        onHoldEnd,
        onClick
    });

    return (
        <Query
            fetchPolicy="no-cache"
            query={CURRENT_USER}
            onError={(err: ApolloError) => {
                //
            }}
            onCompleted={({ currentUser }: any) => {
                setUser(currentUser);
            }}
        >
            {({ loading }: QueryResult) => (
                <Box
                    sx={{
                        position: 'fixed',
                        inset: `${insets.top}px 0 ${insets.bottom}px`,
                        display: 'flex'
                    }}
                >
                    <Dialog
                        maxWidth="xs"
                        open={alert}
                        onClose={() => setAlert(false)}
                        fullWidth
                    >
                        <Box padding={2}>
                            <Typography
                                variant="h6"
                                textAlign="center"
                            >
                                Виртуал модоо бүрэн<br />тарьж дууслаа
                            </Typography>
                            <Box
                                textAlign="center"
                                marginY={2}
                            >
                                <TreeAnimation
                                    progress={100}
                                    code={user.currentTree.type.code}
                                    style={{
                                        display: 'inline-block',
                                        width: '70%'
                                    }}
                                />
                            </Box>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                textAlign="center"
                            >
                                Таны модыг байгальд бодитоор тарьж эхэллээ.
                            </Typography>
                            <Button
                                component={Link}
                                to={generatePath('/tree/:id', {
                                    id: user.currentTree.id
                                })}
                                variant="contained"
                                color="primary"
                                disableElevation
                                fullWidth
                                sx={{
                                    marginTop: 3
                                }}
                            >
                                Сертификат харах
                            </Button>
                        </Box>
                    </Dialog>
                    <Dialog
                        maxWidth="xs"
                        open={open}
                        onClose={() => setOpen(false)}
                        fullWidth
                    >
                        <Box padding={2}>
                            <Typography
                                variant="h6"
                                textAlign="center"
                            >
                                {user.currentTree.type.name}
                            </Typography>
                            <Grid
                                alignItems="center"
                                container
                                sx={{
                                    marginTop: 1
                                }}
                            >
                                <Grid item xs>
                                    <TreeAnimation
                                        progress={user.currentTree.progress}
                                        code={user.currentTree.type.code}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Stack spacing={1}>
                                        <Card variant="outlined">
                                            <Box
                                                sx={{
                                                    paddingY: '4px',
                                                    paddingX: 2
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    Усалсан
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {user.currentTree.drops} литр
                                                </Typography>
                                            </Box>
                                        </Card>
                                        <Card variant="outlined">
                                            <Box
                                                sx={{
                                                    paddingY: '4px',
                                                    paddingX: 2
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    Услах дутуу
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {user.currentTree.requiredDrops - user.currentTree.drops} литр
                                                </Typography>
                                            </Box>
                                        </Card>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Stack
                                alignItems="center"
                                sx={{
                                    marginY: 2
                                }}
                            >
                                <Card
                                    elevation={0}
                                    sx={{
                                        backgroundColor: '#f0f0f0'
                                    }}
                                >
                                    <Box
                                        paddingY="4px"
                                        paddingX="8px"
                                    >
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing="4px"
                                        >
                                            <Place
                                                fontSize="small"
                                                color="disabled"
                                            />
                                            <Typography variant="body2">
                                                {user.currentTree.place.name}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Card>
                            </Stack>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                textAlign="center"
                            >
                                Модны услалт 100% болж дууссаны дараа байгальд бодитоор тарих болно.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                fullWidth
                                onClick={() => {
                                    setOpen(false);
                                }}
                                sx={{
                                    marginTop: 3
                                }}
                            >
                                Ойлголоо
                            </Button>
                        </Box>
                    </Dialog >
                    <Container
                        maxWidth="xs"
                        sx={{
                            position: 'relative',
                            flex: 1,
                            overflow: 'hidden'
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                inset: 0,
                                backgroundImage: `url(/static/images/environments/${environment}/sky.png)`,
                                backgroundSize: 'cover'
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    inset: 0,
                                    backgroundSize: '200% auto',
                                    backgroundImage: 'url(/static/images/clouds.png)',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center 15%'
                                }}
                            >
                                <UserDrops loading={loading} />
                                <MainMenu />
                                <div className="clouds">
                                    {[1, 2, 3].map(value => (
                                        <img
                                            key={value}
                                            src={`/static/images/cloud-${value}.png`}
                                            className={`cloud cloud-${value}`}
                                        />
                                    ))}
                                </div>
                                {query.get('confetti') === 'true' && (
                                    <Fragment>
                                        <WelcomeMessage
                                            sx={{
                                                position: 'absolute',
                                                inset: '30% auto auto 50%',
                                                transform: 'translateX(-50%)'
                                            }}
                                        />
                                        <Anchor
                                            sx={{
                                                position: 'absolute',
                                                inset: '30% 0 auto'
                                            }}
                                        >
                                            {position => (
                                                <Confetti position={position} />
                                            )}
                                        </Anchor>
                                    </Fragment>
                                )}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        inset: 'auto 0px 0px',
                                        paddingTop: '100%',
                                        backgroundSize: '100% auto',
                                        backgroundImage: `url(/static/images/environments/${environment}/ground.png)`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'bottom'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            inset: 'auto 0px 45%'
                                        }}
                                    >
                                        <Animation
                                            lottieRef={lottie}
                                            autoplay={false}
                                            animationData={Watering}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            inset: 'auto auto 50% 50%',
                                            width: '60%',
                                            transform: 'translateX(-50%)'
                                        }}
                                    >
                                        <ButtonBase
                                            disableRipple
                                            onClick={() => {
                                                setOpen(true);
                                            }}
                                        >
                                            <TreeAnimation
                                                id="tree"
                                                progress={progress}
                                                code={user.currentTree.type.code}
                                                onChange={() => {
                                                    animateCSS('#tree', 'bounceIn'); // TODO: leafs particle
                                                }}
                                            />
                                        </ButtonBase>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                inset: 'auto 30% 0'
                                            }}
                                        >
                                            <HorizontalProgress
                                                value={progress}
                                            />
                                        </Box>
                                    </Box>
                                    {loading === false && (
                                        <CollectableDrops
                                            sx={{
                                                position: 'absolute',
                                                inset: '-48px 0px auto'
                                            }}
                                        />
                                    )}
                                </Box>
                                <IconButton
                                    component={Link}
                                    to="/help"
                                    sx={{
                                        position: 'absolute',
                                        inset: 'auto auto 72px 32px',
                                        borderRadius: 2,
                                        border: '2px solid #fff',
                                        backgroundColor: 'rgba(255, 255, 255, 0.6) !important'
                                    }}
                                >
                                    <SvgIcon
                                        component={QuestionMark}
                                        inheritViewBox
                                    />
                                </IconButton>
                                {user.currentTree.drops === user.currentTree.requiredDrops
                                    ?
                                    <Button
                                        component={Link}
                                        to="/choose/type"
                                        variant="contained"
                                        color="inherit"
                                        size="large"
                                        disableElevation
                                        startIcon={
                                            <Add />
                                        }
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            position: 'absolute',
                                            inset: 'auto auto 74px 50%',
                                            transform: 'translateX(-50%)'
                                        }}
                                    >
                                        Шинэ мод тарих
                                    </Button>
                                    :
                                    <ButtonBase
                                        onTouchStart={onTouchStart}
                                        onTouchEnd={onTouchEnd}
                                        disabled={loading || disabled || progress === 100 || user.drops === 0}
                                        disableRipple
                                        sx={{
                                            filter: loading || progress === 100 || user.drops === 0 ? 'grayscale(100%)' : 'none',
                                            position: 'absolute',
                                            width: '50%',
                                            paddingTop: '37.52%',
                                            left: '50%',
                                            bottom: 10,
                                            transform: 'translateX(-50%)',
                                            backgroundSize: 'cover',
                                            backgroundImage: 'url(/static/images/button.png)',
                                            '&:active': {
                                                backgroundImage: 'url(/static/images/button-pressed.png)'
                                            }
                                        }}
                                    />
                                }
                            </Box>
                        </Box>
                    </Container>
                </Box>
            )}
        </Query>
    );
}));
