import { Box, Card, Grid, Typography } from '@mui/material';

type WayToCollectWaterProps = {
    avatar: JSX.Element;
    primary: string;
    secondary: string;
    children?: JSX.Element;
};

export const WayToCollectWater = (props: WayToCollectWaterProps): JSX.Element => {
    const {
        avatar,
        primary,
        secondary,
        children
    } = props;

    return (
        <Card variant="outlined">
            <Box padding={2}>
                <Grid
                    columnSpacing={2}
                    container
                >
                    <Grid item>
                        {avatar}
                    </Grid>
                    <Grid xs item>
                        <Typography variant="subtitle1">
                            {primary}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                        >
                            {secondary}
                        </Typography>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};