import { ApolloProvider } from '@apollo/client';
import { Wrapper } from '@googlemaps/react-wrapper';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { apolloClient } from './plugins/apollo-client';
import { persistor, store } from './redux/configureStore';
import { Router } from './Router';
import { light } from './theme/muiTheme';

export default function App() {
    return (
        <ApolloProvider client={apolloClient}>
            <Provider store={store}>
                <PersistGate
                    loading={null}
                    persistor={persistor}
                >
                    <ThemeProvider theme={light}>
                        <CssBaseline />
                        <SnackbarProvider
                            maxSnack={2}
                            hideIconVariant
                        >
                            <Wrapper apiKey="AIzaSyCdVLToJxG-ESeLWrq5Q1meb_4fvHyZ4hM">
                                <Router />
                            </Wrapper>
                        </SnackbarProvider>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </ApolloProvider>
    );
};
