import { Avatar, Box, Card, CardActionArea, Grid, Radio, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';

type RadioMethodProps = {
    disabled?: boolean;
    avatarSrc: string;
    price: number;
    amount: number;
    suffix: string;
    checked: boolean;
    onChangeValue: (checked: boolean) => void;
};

export const RadioMethod = (props: RadioMethodProps): JSX.Element => {
    const {
        disabled = false,
        avatarSrc,
        price,
        amount,
        suffix,
        checked,
        onChangeValue
    } = props;

    const formattedAmount = (
        <NumericFormat
            value={amount}
            displayType="text"
            suffix={suffix}
            thousandSeparator=","
        />
    );

    return (
        <Card
            variant="outlined"
            sx={{
                opacity: disabled ? 0.4 : 1,
                borderColor: checked
                    ? 'primary.main'
                    : 'divider',
                backgroundColor: checked
                    ? '#0c4da21a'
                    : '#fff'
            }}
        >
            <CardActionArea
                disabled={disabled}
                onClick={() => {
                    onChangeValue(true);
                }}
            >
                <Box padding={1}>
                    <Grid
                        alignItems="center"
                        columnSpacing={2}
                        container
                    >
                        <Grid item>
                            <Radio
                                size="small"
                                checked={checked}
                                disableRipple
                                sx={{
                                    padding: 0,
                                    color: 'text.disabled'
                                }}
                            />
                        </Grid>
                        <Grid xs item>
                            <div>
                                <Typography variant="subtitle2">
                                    {formattedAmount}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    1 литр ус = {price}{suffix}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <Avatar
                                variant="rounded"
                                src={avatarSrc}
                                sx={{
                                    backgroundColor: 'transparent'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </CardActionArea>
        </Card>
    );
};