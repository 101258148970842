import { Box, BoxProps } from '@mui/material';

type PulseProps = BoxProps & {
    circleSize?: number;
};

export const Pulse = (props: PulseProps): JSX.Element => {
    const {
        circleSize = 100,
        ...other
    } = props;

    return (
        <Box {...other} position="relative">
            <div className="animate__animated animate__zoomIn animate__infinite animate__slower">
                <div className="animate__animated animate__fadeOut animate__infinite animate__slower">
                    <Box
                        sx={{
                            width: circleSize,
                            height: circleSize,
                            borderRadius: 50,
                            backgroundColor: 'rgba(255, 255, 255, 0.6)'
                        }}
                    />
                </div>
            </div>
            <Box
                sx={{
                    position: 'absolute',
                    inset: 0
                }}
            >
                <div className="animate__animated animate__zoomIn animate__infinite animate__slower animate__delay-1s">
                    <div className="animate__animated animate__fadeOut animate__infinite animate__slower animate__delay-1s">
                        <Box
                            sx={{
                                width: circleSize,
                                height: circleSize,
                                borderRadius: 50,
                                backgroundColor: 'rgba(255, 255, 255, 0.6)'
                            }}
                        />
                    </div>
                </div>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    inset: 0
                }}
            >
                <div className="animate__animated animate__zoomIn animate__infinite animate__slower animate__delay-2s">
                    <div className="animate__animated animate__fadeOut animate__infinite animate__slower animate__delay-2s">
                        <Box
                            sx={{
                                width: circleSize,
                                height: circleSize,
                                borderRadius: 50,
                                backgroundColor: 'rgba(255, 255, 255, 0.6)'
                            }}
                        />
                    </div>
                </div>
            </Box>
        </Box>
    );
};