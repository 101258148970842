import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Box, Container } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { GoogleMap } from '../components/GoogleMap';
import { PageHeader } from '../components/PageHeader';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';

const PLACES = gql`
    query Places {
        places {
            edges {
                id
                name
                location
            }
        }
    }
`;

type MapProps = {
    setColors: (colors: Colors) => void;
    insets: Insets;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setColors
};

export const Map = connect(mapStateToProps, mapDispatchToProps)(withUser<MapProps>((props): JSX.Element => {
    const {
        setColors,
        insets
    } = props;

    useEffect(() => {
        changeThemeColor('#13a456');
        setColors({
            top: '#13a456',
            bottom: '#fff'
        });
    }, []);

    const parseLocation = (location: string) => {
        const [
            lat,
            lng
        ] = location.split(',');
        return {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        };
    };

    return (
        <Container maxWidth="xs">
            <PageHeader
                color="#13a456"
                closeButtonProps={{
                    component: Link,
                    to: '/about-us'
                }}
            />
            <Query
                fetchPolicy="no-cache"
                query={PLACES}
                onError={(err: ApolloError) => {
                    //
                }}
            >
                {({ data }: QueryResult) => (
                    <Box
                        sx={{
                            position: 'fixed',
                            inset: `${insets.top + 64}px auto 0`,
                            width: '100%',
                            maxWidth: 444,
                            zIndex: 999
                        }}
                    >
                        <GoogleMap
                            markers={data?.places.edges.map(({ location }: any) => parseLocation(location)) || []}
                            zoom={4}
                            center={{
                                lat: 46.9810112,
                                lng: 103.7781429
                            }}
                        />
                    </Box>
                )}
            </Query>
        </Container>
    );
}));
