import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query, Subscription } from '@apollo/client/react/components';
import { Badge, Box, Fab, FabProps, Stack, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Close } from '../assets/svg';

const UNREAD_NOTIFICATIONS = gql`
    query Notifications {
        unreadNotifications {
            count
        }
    }
`;

const FRIEND_REQUESTS = gql`
    query FriendRequests {
        friendRequests {
            count
        }
    }
`;

const STEAL_CHARGES = gql`
    query StealCharges {
        stealCharges
    }
`;

const ON_NOTIFICATION_ADDED = gql`
    subscription OnNotificationAdded {
        notificationAdded {
            id
        }
    }
`;

type MenuButtonProps = FabProps & {
    component?: React.ElementType;
    to?: string;
};

const MenuButton = styled(Fab)<MenuButtonProps>(() => ({
    boxShadow: 'none',
    border: '3px solid #fff',
    backgroundColor: 'rgba(255, 255, 255, 0.6)'
}));

const MenuButtonIcon = styled('img')(() => ({
    maxWidth: 32,
    maxHeight: 32
}));

export const MainMenu = (): JSX.Element => {
    const [
        count,
        setCount
    ] = useState(0);

    return (
        <Box
            sx={{
                position: 'absolute',
                inset: '12px 16px auto auto',
                zIndex: 999
            }}
        >
            <Subscription
                subscription={ON_NOTIFICATION_ADDED}
                onSubscriptionData={() => {
                    setCount(count + 1);
                }}
            />
            <Stack
                alignItems="center"
                spacing={4}
            >
                <Stack spacing={1}>
                    <MenuButton
                        onClick={() => {
                            window.ReactNativeWebView?.postMessage(JSON.stringify({
                                type: 'CLOSE'
                            }));
                        }}
                    >
                        <SvgIcon
                            component={Close}
                            fontSize="small"
                            inheritViewBox
                            sx={{
                                color: '#384d61'
                            }}
                        />
                    </MenuButton>
                    <MenuButton
                        component={Link}
                        to="/about-us"
                        sx={{
                            backgroundSize: 'cover',
                            backgroundImage: 'url(/static/images/pine-tree.png)'
                        }}
                    />
                    <Query
                        fetchPolicy="no-cache"
                        query={UNREAD_NOTIFICATIONS}
                        onError={(err: ApolloError) => {
                            //
                        }}
                        onCompleted={(data: any) => {
                            setCount(data.unreadNotifications.count);
                        }}
                    >
                        {() => (
                            <MenuButton
                                component={Link}
                                to="/notifications"
                            >
                                <Badge badgeContent={count}>
                                    <MenuButtonIcon
                                        src="/static/images/bell.png"
                                    />
                                </Badge>
                            </MenuButton>
                        )}
                    </Query>
                    <Query
                        fetchPolicy="no-cache"
                        query={FRIEND_REQUESTS}
                        onError={(err: ApolloError) => {
                            //
                        }}
                    >
                        {({ data }: QueryResult) => (
                            <MenuButton
                                component={Link}
                                to="/friends"
                            >
                                <Badge badgeContent={data?.friendRequests.count}>
                                    <MenuButtonIcon
                                        src="/static/images/users.png"
                                    />
                                </Badge>
                            </MenuButton>
                        )}
                    </Query>
                    <Query
                        fetchPolicy="no-cache"
                        query={STEAL_CHARGES}
                        onError={(err: ApolloError) => {
                            //
                        }}
                    >
                        {({ data }: QueryResult) => (
                            <MenuButton
                                component={Link}
                                to="/steal"
                            >
                                <Badge badgeContent={data?.stealCharges}>
                                    <MenuButtonIcon
                                        src="/static/images/spy.png"
                                    />
                                </Badge>
                            </MenuButton>
                        )}
                    </Query>
                </Stack>
            </Stack>
        </Box>
    );
};