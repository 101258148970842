import { Box, Card, CardActionArea, Grid, Radio, Typography } from '@mui/material';

type RadioDropsProps = {
    drops: number;
    checked: boolean;
    onChangeValue: (checked: boolean) => void;
};

export const RadioDrops = (props: RadioDropsProps): JSX.Element => {
    const {
        drops,
        checked,
        onChangeValue
    } = props;

    return (
        <Card
            variant="outlined"
            sx={{
                borderColor: checked
                    ? 'primary.main'
                    : 'divider',
                backgroundColor: checked
                    ? '#0c4da21a'
                    : '#fff'
            }}
        >
            <CardActionArea
                onClick={() => {
                    onChangeValue(true);
                }}
            >
                <Box
                    paddingX={1}
                    paddingY={2}
                >
                    <Grid
                        alignItems="center"
                        columnSpacing={1}
                        container
                    >
                        <Grid item>
                            <Radio
                                size="small"
                                checked={checked}
                                disableRipple
                                sx={{
                                    padding: 0,
                                    color: 'text.disabled'
                                }}
                            />
                        </Grid>
                        <Grid xs item>
                            <Typography variant="subtitle2">
                                {drops} литр
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </CardActionArea>
        </Card>
    );
};