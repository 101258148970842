import { Box, Button, Card, CardActionArea, Dialog, Grid, IconButton, Radio, Stack, SvgIcon, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Info } from '../assets/svg';
import { createUseState } from './UseState';

type RadioPlaceProps = {
    name: string;
    checked: boolean;
    partnerName: string;
    partnerLogo: string;
    partnerDescription: string;
    onChangeValue: (checked: boolean) => void;
};

const BooleanState = createUseState<boolean>();

export const RadioPlace = (props: RadioPlaceProps): JSX.Element => {
    const {
        name,
        checked,
        partnerName,
        partnerLogo,
        partnerDescription,
        onChangeValue
    } = props;

    return (
        <BooleanState defaultValue={false}>
            {(open, setOpen) => (
                <Fragment>
                    <Dialog
                        maxWidth="xs"
                        open={open}
                        onClose={() => setOpen(false)}
                        fullWidth
                    >
                        <Box padding={2}>
                            <Stack alignItems="center">
                                <Box
                                    component="img"
                                    src={`${process.env.REACT_APP_API_URL}${partnerLogo}`}
                                    sx={{
                                        marginY: 3,
                                        width: 'auto',
                                        height: 'auto',
                                        maxWidth: '100%',
                                        maxHeight: 40
                                    }}
                                />
                            </Stack>
                            <Typography
                                variant="h6"
                                textAlign="center"
                                sx={{
                                    marginBottom: 1
                                }}
                            >
                                {partnerName}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                textAlign="center"
                            >
                                {partnerDescription}
                            </Typography>
                            <Box textAlign="center">
                                <Button
                                    size="large"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                    sx={{
                                        marginTop: 2
                                    }}
                                >
                                    Хаах
                                </Button>
                            </Box>
                        </Box>
                    </Dialog>
                    <Card
                        variant="outlined"
                        sx={{
                            borderColor: checked
                                ? 'primary.main'
                                : 'divider',
                            backgroundColor: checked
                                ? '#0c4da21a'
                                : '#fff'
                        }}
                    >
                        <CardActionArea
                            onClick={() => {
                                onChangeValue(true);
                            }}
                        >
                            <Box
                                padding={2}
                                paddingRight={1}
                            >
                                <Grid
                                    alignItems="center"
                                    columnSpacing={2}
                                    container
                                >
                                    <Grid item>
                                        <Radio
                                            size="small"
                                            checked={checked}
                                            disableRipple
                                            sx={{
                                                padding: 0,
                                                color: 'text.disabled'
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs item>
                                        <Typography variant="body1">
                                            {name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Тарих компани: {partnerName}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpen(true);
                                            }}
                                        >
                                            <SvgIcon
                                                component={Info}
                                                inheritViewBox
                                            />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardActionArea>
                    </Card>
                </Fragment>
            )}
        </BooleanState>
    );
};