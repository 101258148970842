import { Colors, Insets } from './reducer';

export const setColors = (colors: Colors) => ({
    type: 'SET_COLORS',
    payload: {
        colors
    }
});

export const setInsets = (insets: Insets) => ({
    type: 'SET_INSETS',
    payload: {
        insets
    }
});

export const setPlatform = (platform: string) => ({
    type: 'SET_PLATFORM',
    payload: {
        platform
    }
});
