import { ArrowForward } from '@mui/icons-material';
import { Card, CardActionArea, Chip, Stack, Typography } from '@mui/material';
import { Link, generatePath } from 'react-router-dom';
import { TreeAnimation } from './TreeAnimation';

type TreeProps = {
    id: string;
    name: string;
    code: 'aspen' | 'elm' | 'pine' | 'willow' | 'sea-buckthorn';
    progress: number;
};

export const Tree = (props: TreeProps): JSX.Element => {
    const {
        id,
        name,
        code,
        progress
    } = props;

    return (
        <Card
            variant="outlined"
            sx={{
                backgroundColor: progress !== 100
                    ? '#fffcf3'
                    : '#fff'
            }}
        >
            <CardActionArea
                component={Link}
                to={
                    progress !== 100
                        ? generatePath('/home')
                        : generatePath('/tree/:id', { id })
                }
                sx={{
                    padding: 2
                }}
            >
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <Typography variant="subtitle1">
                        {name}
                    </Typography>
                    {progress === 100
                        ?
                        <Chip
                            label="100%"
                            size="small"
                            disabled
                            sx={{
                                borderRadius: '4px'
                            }}
                        />
                        :
                        <Chip
                            label={`${progress}%`}
                            size="small"
                            color="primary"
                            sx={{
                                borderRadius: '4px'
                            }}
                        />
                    }
                </Stack>
                <Stack alignItems="center">
                    <TreeAnimation
                        progress={progress}
                        code={code}
                        style={{
                            height: 112
                        }}

                    />
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={{
                        marginTop: 2
                    }}
                >
                    <Typography variant="subtitle2">
                        Дэлгэрэнгүй
                    </Typography>
                    <ArrowForward
                        fontSize="small"
                        color="inherit"
                    />
                </Stack>
            </CardActionArea>
        </Card>
    );
};