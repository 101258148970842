import { ArrowForward } from '@mui/icons-material';
import { Avatar, Box, Card, CardActionArea, Grid, Stack, Typography } from '@mui/material';
import { generatePath, Link } from 'react-router-dom';

type FriendProps = {
    disabled?: boolean;
    id: string;
    avatar: string;
    nickname: string;
    treesCount: number;
};

export const FriendVariant = (props: FriendProps): JSX.Element => {
    const {
        id,
        avatar,
        nickname,
        treesCount,
        ...other
    } = props;

    return (
        <Card variant="outlined">
            <CardActionArea
                {...other}
                component={Link}
                to={generatePath('/friend/:id', { id })}
                sx={{
                    padding: '8px 16px',
                    opacity: other.disabled ? 0.4 : 1,
                }}
            >
                <Grid
                    columnSpacing={1}
                    alignItems="center"
                    container
                >
                    <Grid xs item>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing="12px"
                        >
                            <Avatar
                                src={`/static/images/avatars/${avatar}.png`}
                                sx={{
                                    width: 48,
                                    height: 48,
                                    backgroundColor: '#f0f0f0'
                                }}
                            />
                            <div>
                                <Typography variant="subtitle1">
                                    {nickname}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.disabled"
                                >
                                    {treesCount} мод
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Box color="text.secondary">
                            <ArrowForward
                                sx={{
                                    display: 'block'
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
};