import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Empty } from '../components/Empty';
import { FriendRequest } from '../components/FriendRequest';
import { withUser } from '../hoc/withUser';

const FRIEND_REQUESTS = gql`
    query FriendRequests(
        $skip: Int
        $take: Int
    ) {
        friendRequests(
            skip: $skip
            take: $take
        ) {
            count
            edges {
                id
                avatar
                nickname
                trees {
                    count
                }
            }
        }
    }
`;

export const FriendRequests = withUser((): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();

    const [
        skip,
        setSkip
    ] = useState(0);
    const [
        friendRequests,
        setFriendRequests
    ] = useState<any[]>([]);

    return (
        <Box padding={2}>
            <Query
                fetchPolicy="no-cache"
                query={FRIEND_REQUESTS}
                variables={{
                    skip,
                    take: 20
                }}
                onError={(err: ApolloError) => {
                    enqueueSnackbar(err.message, {
                        variant: 'error'
                    });
                }}
                onCompleted={(data: any) => {
                    setFriendRequests([
                        ...friendRequests,
                        ...data.friendRequests.edges
                    ]);
                }}
            >
                {({ data, loading }: QueryResult) => (
                    <Stack spacing={1}>
                        {friendRequests.map(({ id, avatar, nickname, trees }: any) => (
                            <div key={id}>
                                <FriendRequest
                                    id={id}
                                    avatar={avatar}
                                    nickname={nickname}
                                    treesCount={trees.count}
                                    onRespond={userId => {
                                        setFriendRequests(friendRequests.filter(({ id }) => id !== userId));
                                    }}
                                />
                            </div>
                        ))}
                        {data && (
                            <InView
                                style={{
                                    height: Number(data.friendRequests.count !== friendRequests.length)
                                }}
                                onChange={visible => {
                                    if (visible && data.friendRequests.count > friendRequests.length) {
                                        setSkip(friendRequests.length);
                                    }
                                }}
                            />
                        )}
                        {loading && (
                            <Box textAlign="center">
                                <CircularProgress />
                            </Box>
                        )}
                        {loading === false && friendRequests.length === 0 && (
                            <Empty
                                top={112}
                            />
                        )}
                    </Stack>
                )}
            </Query>
        </Box>
    );
});