import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export type Colors = {
    top: string;
    bottom: string;
};

export type Insets = {
    top: number;
    bottom: number;
};

export type AppState = {
    colors: Colors;
    insets: Insets;
    platform: string;
};

const initialState: AppState = {
    colors: {
        top: '#fff',
        bottom: '#fff'
    },
    insets: {
        top: 0,
        bottom: 0
    },
    platform: 'ios'
};

const handlers = {
    SET_COLORS: (state: AppState, { payload }: PayloadAction<{ colors: Colors; }>) => ({
        ...state,
        ...payload
    }),
    SET_INSETS: (state: AppState, { payload }: PayloadAction<{ insets: Insets; }>) => ({
        ...state,
        ...payload
    }),
    SET_PLATFORM: (state: AppState, { payload }: PayloadAction<{ platform: string; }>) => ({
        ...state,
        ...payload
    })
};

export const app = createReducer(
    initialState,
    handlers
);
