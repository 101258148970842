import { Box, ButtonBase, Card, Container, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Pulse } from '../components/Pulse';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';

type PlantSeedProps = {
    setColors: (colors: Colors) => void;
    insets: Insets;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setColors
};

export const PlantSeed = connect(mapStateToProps, mapDispatchToProps)(withUser<PlantSeedProps>((props): JSX.Element => {
    const {
        setColors,
        insets
    } = props;

    useEffect(() => {
        changeThemeColor('#a7d5eb');
        setColors({
            top: '#a7d5eb',
            bottom: '#90c7fa'
        });
    }, []);

    return (
        <Box
            sx={{
                position: 'fixed',
                inset: `${insets.top}px 0 ${insets.bottom}px`,
                display: 'flex'
            }}
        >
            <Container
                maxWidth="xs"
                sx={{
                    position: 'relative',
                    flex: 1,
                    overflow: 'hidden'
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        inset: 0,
                        backgroundImage: 'url(/static/images/environments/light/sky.png)',
                        backgroundSize: 'cover'
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            inset: 0,
                            backgroundSize: '200% auto',
                            backgroundImage: 'url(/static/images/clouds.png)',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center 15%'
                        }}
                    >
                        <div className="clouds">
                            {[1, 2, 3].map(value => (
                                <img
                                    key={value}
                                    src={`/static/images/cloud-${value}.png`}
                                    className={`cloud cloud-${value}`}
                                />
                            ))}
                        </div>
                        <Box
                            sx={{
                                position: 'absolute',
                                inset: 'auto 0px 0px',
                                paddingTop: '100%',
                                backgroundSize: '100% auto',
                                backgroundImage: 'url(/static/images/environments/light/ground.png)',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'bottom'
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    inset: 'auto auto 58% 50%',
                                    transform: 'translateX(-50%)'
                                }}
                            >
                                <Pulse
                                    circleSize={300}
                                    sx={{
                                        position: 'absolute',
                                        inset: '80% auto auto 50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                />
                                <Stack
                                    alignItems="center"
                                    spacing={6}
                                >
                                    <Card
                                        variant="elevation"
                                        elevation={0}
                                        sx={{
                                            position: 'relative',
                                            overflow: 'visible',
                                            padding: '8px 12px',
                                            '&::after': {
                                                content: '" "',
                                                position: 'absolute',
                                                inset: 'auto',
                                                left: 'calc(50% - 8px)',
                                                bottom: -16,
                                                borderColor: 'transparent',
                                                borderStyle: 'solid',
                                                borderWidth: 8,
                                                borderTopColor: '#fff'
                                            }
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            textAlign="center"
                                            noWrap
                                        >
                                            Энд дарж модоо сонгоно уу
                                        </Typography>
                                    </Card>
                                    <ButtonBase
                                        component={Link}
                                        to="/choose/type"
                                        disableRipple
                                        sx={{
                                            width: 80,
                                            height: 80,
                                            backgroundSize: 'cover',
                                            backgroundImage: 'url(/static/images/seed-bag.png)'
                                        }}
                                    />
                                </Stack>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                filter: 'grayscale(100%)',
                                position: 'absolute',
                                width: '50%',
                                paddingTop: '37.52%',
                                left: '50%',
                                bottom: 10,
                                transform: 'translateX(-50%)',
                                backgroundSize: 'cover',
                                backgroundImage: 'url(/static/images/button.png)'
                            }}
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}));
