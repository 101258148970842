import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Add, Search } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, Container, debounce, InputAdornment, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Thief } from '../assets/svg';
import { Empty } from '../components/Empty';
import { FriendVariant } from '../components/FriendVariant';
import { PageHeader } from '../components/PageHeader';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';

const FRIENDS = gql`
    query Friends(
        $search: String
        $skip: Int
        $take: Int
    ) {
        friends(
            search: $search
            skip: $skip
            take: $take
        ) {
            count
            edges {
                id
                avatar
                nickname
                trees {
                    count
                }
                unclaimedRewards {
                    count
                }
            }
        }
    }
`;

const STEAL_CHARGES = gql`
    query StealCharges {
        stealCharges
    }
`;

type StealProps = {
    setColors: (colors: Colors) => void;
    insets: Insets;
};

type StyledChipProps = {
    label: string;
};

const StyledChip = styled((props: StyledChipProps) => (
    <Chip {...props} size="small" component="span" />
))({
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    color: '#fff'
});

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setColors
};

export const Steal = connect(mapStateToProps, mapDispatchToProps)(withUser<StealProps>((props): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        setColors,
        insets
    } = props;

    const [
        search,
        setSearch
    ] = useState('');
    const [
        skip,
        setSkip
    ] = useState(0);
    const [
        friends,
        setFriends
    ] = useState<any[]>([]);

    useEffect(() => {
        changeThemeColor('#2b2d42');
        setColors({
            top: '#2b2d42',
            bottom: '#fff'
        });
    }, []);

    return (
        <Container maxWidth="xs">
            <Query
                fetchPolicy="no-cache"
                query={STEAL_CHARGES}
                onError={(err: ApolloError) => {
                    //
                }}
            >
                {({ data }: QueryResult) => (
                    <PageHeader
                        svgIcon={Thief}
                        title="Ус хулгайлах"
                        color="#2b2d42"
                        closeButtonProps={{
                            component: Link,
                            to: '/home'
                        }}
                        description={
                            <span>
                                Танд хулгайлах <StyledChip label={`${data?.stealCharges ?? 0} эрх`} /> байна
                            </span>
                        }
                    />
                )}
            </Query>
            <Box
                sx={{
                    position: 'fixed',
                    inset: `${insets.top + 80}px auto auto`,
                    width: '100%',
                    maxWidth: 444,
                    zIndex: 999,
                    padding: 2,
                    backgroundColor: '#fff'
                }}
            >
                <TextField
                    placeholder="Нэр, утасны дугаараар хайх"
                    variant="outlined"
                    onChange={debounce(e => {
                        setFriends([]);
                        setSkip(0);
                        setSearch(e.target.value);
                    }, 500)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                    fullWidth
                />
            </Box>
            <Box
                style={{
                    paddingTop: 152
                }}
            />
            <Box padding={2}>
                <Query
                    fetchPolicy="no-cache"
                    query={FRIENDS}
                    variables={{
                        search,
                        skip,
                        take: 20
                    }}
                    onError={(err: ApolloError) => {
                        enqueueSnackbar(err.message, {
                            variant: 'error'
                        });
                    }}
                    onCompleted={(data: any) => {
                        setFriends([
                            ...friends,
                            ...data.friends.edges
                        ]);
                    }}
                >
                    {({ data, loading }: QueryResult) => (
                        <Stack spacing={1}>
                            {friends.map(({ id, avatar, nickname, trees, unclaimedRewards }: any) => (
                                <div key={id}>
                                    <FriendVariant
                                        disabled={unclaimedRewards.count === 0}
                                        id={id}
                                        avatar={avatar}
                                        nickname={nickname}
                                        treesCount={trees.count}
                                    />
                                </div>
                            ))}
                            {data && (
                                <InView
                                    style={{
                                        height: Number(data.friends.count !== friends.length)
                                    }}
                                    onChange={visible => {
                                        if (visible && data.friends.count > friends.length) {
                                            setSkip(friends.length);
                                        }
                                    }}
                                />
                            )}
                            {loading && (
                                <Box textAlign="center">
                                    <CircularProgress />
                                </Box>
                            )}
                            {loading === false && friends.length === 0 && (
                                <Empty
                                    title="Найз байхгүй"
                                    description="Та зөвхөн найзуудаасаа ус хулгайлах боломжтой"
                                    top={152}
                                    action={
                                        <Button
                                            component={Link}
                                            to="/add/friend"
                                            variant="outlined"
                                            color="inherit"
                                            size="small"
                                            startIcon={
                                                <Add />
                                            }
                                            sx={{
                                                borderColor: 'rgba(0, 0, 0, 0.12)'
                                            }}
                                        >
                                            Найз нэмэх
                                        </Button>
                                    }
                                />
                            )}
                        </Stack>
                    )}
                </Query>
            </Box>
        </Container>
    );
}));
