import { Box, BoxProps } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { animateCSS } from '../lib/helpers';

export const WelcomeMessage = (props: BoxProps): JSX.Element | null => {
    const [_, setSearchParams] = useSearchParams();

    useEffect(() => {
        animateCSS('#welcome-message', 'fadeOut delay-5s')
            .then(() => {
                setSearchParams(new URLSearchParams());
            });
    }, []);

    return (
        <Box {...props} >
            <Box className="animate__animated animate__jackInTheBox">
                <Box
                    id="welcome-message"
                    component="img"
                    src="/static/images/congrats-text.png"
                    width={160}
                    sx={{
                        '&:not(.animate__animated)': {
                            opacity: 0
                        }
                    }}
                />
            </Box>
        </Box>
    );
};