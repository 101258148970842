import { Avatar, Badge, Box, Stack, SvgIcon, Typography } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import { mn } from 'date-fns/locale';
import { Gift, Thief } from '../assets/svg';

type AlertProps = {
    id: string;
    type: string;
    readAt?: number | null;
    createdAt: number;
    drops?: number;
    friend?: {
        nickname: string;
    };
};

export const Alert: React.FunctionComponent<AlertProps> = props => {
    const {
        id,
        type,
        readAt,
        createdAt,
        drops,
        friend
    } = props;

    return (
        <div>
            <Box
                sx={{
                    padding: '12px 16px'
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    <Badge
                        badgeContent={readAt ? 0 : 1}
                        variant="dot"
                        color="warning"
                    >
                        <Avatar
                            variant={type === 'WALK' ? 'square' : 'circular'}
                            src={type === 'WALK' ? 'static/images/walking.png' : undefined}
                            sx={{
                                width: 48,
                                height: 48,
                                backgroundColor: type === 'WALK' ? 'transparent' : '#f0f0f0'
                            }}
                        >
                            {type === 'GIFT' && (
                                <SvgIcon
                                    component={Gift}
                                    inheritViewBox
                                />
                            )}
                            {type === 'STEAL' && (
                                <SvgIcon
                                    component={Thief}
                                    inheritViewBox
                                />
                            )}
                        </Avatar>
                    </Badge>
                    <Box>
                        {type === 'GIFT' && (
                            <Typography variant="body1">
                                <strong>{friend?.nickname}</strong> танд <strong>{drops}литр</strong> ус бэлэг илгээлээ.
                            </Typography>
                        )}
                        {type === 'STEAL' && (
                            <Typography variant="body1">
                                <strong>{friend?.nickname}</strong> танаас <strong>{drops}литр</strong> ус хулгайллаа.
                            </Typography>
                        )}
                        {type === 'WALK' && (
                            <Typography variant="body1">
                                Таны алхалтыг тооцоолж <strong>{drops} литр</strong> ус бэлэглэлээ.
                            </Typography>
                        )}
                        <Typography
                            variant="body2"
                            color="text.disabled"
                        >
                            {formatDistanceToNowStrict(new Date(createdAt), {
                                addSuffix: true,
                                locale: mn
                            })}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
        </div>
    );
};