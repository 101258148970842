import { ApolloError, gql, useMutation } from '@apollo/client';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { default as Animation } from 'lottie-react';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { default as Slider } from 'react-slick';
import { One, Three, Two } from '../assets/lottie/intro';
import { IntroDots } from '../components/IntroDots';
import { CurrentUserFields } from '../fragments/current-user-fields';
import { useQuery } from '../hooks/useQuery';
import { changeThemeColor } from '../lib/helpers';
import { setColors, setInsets, setPlatform } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { setAccessToken, setUser } from '../redux/auth/actions';
import { RootState } from '../redux/configureStore';

const SIGN_IN = gql`
    ${CurrentUserFields}
    mutation SignIn(
        $accessToken: String
        $refreshToken: String
    ) {
        signInWithPayon(input: {
            accessToken: $accessToken
            refreshToken: $refreshToken
        }) {
            accessToken
            user {
                ...CurrentUserFields
            }
        }
    }
`;

type IntroProps = {
    setAccessToken: (accessToken: string) => void;
    setUser: (user: any) => void;
    setInsets: (insets: Insets) => void;
    setColors: (colors: Colors) => void;
    setPlatform: (string: string) => void;
    insets: Insets;
};

const Strong = styled('strong')(({ theme }) => ({
    color: theme.palette.primary.main
}));

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setAccessToken,
    setUser,
    setInsets,
    setColors,
    setPlatform
};

export const Intro = connect(mapStateToProps, mapDispatchToProps)((props: IntroProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        setAccessToken,
        setUser,
        setInsets,
        setColors,
        setPlatform,
        insets
    } = props;

    const redirect = useNavigate();
    const slick = useRef<Slider>(null);
    const query = useQuery();
    const accessToken = query.get('accessToken');
    const refreshToken = query.get('refreshToken');
    const safeTop = query.get('safeTop');
    const safeBottom = query.get('safeBottom');
    const platform = query.get('platform');

    const [
        mutate,
        mutationResult
    ] = useMutation(SIGN_IN, {
        variables: {
            accessToken,
            refreshToken
        },
        onError(err: ApolloError) {
            enqueueSnackbar(err.message, {
                variant: 'error'
            });
        },
        onCompleted({ signInWithPayon }) {
            setAccessToken(signInWithPayon.accessToken);
            setUser(signInWithPayon.user);
            if (signInWithPayon.user.currentTree) {
                redirect('/home');
            }
        }
    });

    const [
        step,
        setStep
    ] = useState(0);

    useEffect(() => {
        changeThemeColor('#fff');
        setTimeout(() => {
            mutate();
        }, 3000);
        setPlatform(platform || 'ios');
        setInsets({
            top: parseInt(safeTop || '0'),
            bottom: parseInt(safeBottom || '0')
        });
        setColors({
            top: '#fff',
            bottom: '#fff'
        });
    }, []);

    if (mutationResult.data === undefined) {
        return (
            <Box
                sx={{
                    position: 'fixed',
                    inset: '0px auto 0px 50%',
                    transform: 'translateX(-50%)',
                    width: '100%',
                    maxWidth: 444,
                    zIndex: 999,
                    backgroundImage: 'url(/static/images/splash.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#fff'
                }}
            >
                <CircularProgress
                    color="inherit"
                    sx={{
                        position: 'absolute',
                        inset: 'auto 32px 24px auto'
                    }}
                />
            </Box>
        );
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    position: 'fixed',
                    inset: 'auto',
                    bottom: `${insets.bottom}px`,
                    zIndex: 999,
                    width: '100%',
                    maxWidth: 444,
                    padding: 3,
                    backgroundColor: '#fff'
                }}
            >
                <IntroDots
                    step={step}
                    onChangeStep={value => {
                        slick.current?.slickGoTo(value);
                    }}
                    onStart={() => {
                        redirect('/create/avatar');
                    }}
                />
            </Box>
            <Box textAlign="center">
                <Box
                    component="img"
                    src="/static/images/logo.png"
                    sx={{
                        marginTop: 7,
                        marginBottom: 3,
                        width: 120,
                        height: 'auto'
                    }}
                />
            </Box>
            <Slider
                ref={slick}
                arrows={false}
                accessibility={false}
                infinite={false}
                beforeChange={(_, step) => {
                    setStep(step);
                }}
            >
                <Box
                    sx={{
                        padding: 5,
                        paddingBottom: '128.5px'
                    }}
                >
                    <Box
                        component="img"
                        src="/static/images/intro.png"
                        sx={{
                            display: 'inline-block',
                            width: 'auto',
                            margin: '24px auto',
                            maxHeight: 190
                        }}
                    />
                    <Typography
                        variant="h5"
                        textAlign="center"
                    >
                        Тоглоом тоглоод модтой болоорой
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        textAlign="center"
                        sx={{
                            marginTop: 2
                        }}
                    >
                        Таны гар утсан дээрх мод ургаж дуусахад TreeOn хөтөлбөр <Strong>бодит мод</Strong> болгон тарих болно.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        padding: 5,
                        paddingBottom: '128.5px'
                    }}
                >
                    <Animation
                        animationData={One}
                        style={{
                            margin: '24px auto',
                            height: 190
                        }}
                    />
                    <Typography
                        variant="h5"
                        textAlign="center"
                    >
                        Модоо услаарай
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        textAlign="center"
                        sx={{
                            marginTop: 2
                        }}
                    >
                        Мод бүрд шаардлагатай усны хэмжээ байх ба та модоо <Strong>тогтмол услан</Strong> ургуулаарай.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        padding: 5,
                        paddingBottom: '128.5px'
                    }}
                >
                    <Animation
                        animationData={Two}
                        style={{
                            margin: '24px auto',
                            height: 190
                        }}
                    />
                    <Typography
                        variant="h5"
                        textAlign="center"
                    >
                        Ус цуглуулах маш<br />хялбар
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        textAlign="center"
                        sx={{
                            marginTop: 2
                        }}
                    >
                        Та ердөө <Strong>алхаад л</Strong> модоо ургуулах устай болох боломжтой. Мөн худалдаж авах болон <Strong>PayOn бонус</Strong> оноогоо ашиглаж болно.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        padding: 5,
                        paddingBottom: '128.5px'
                    }}
                >
                    <Animation
                        animationData={Three}
                        style={{
                            margin: '24px auto',
                            height: 190
                        }}
                    />
                    <Typography
                        variant="h5"
                        textAlign="center"
                    >
                        Мод эзэмшигчийн<br />сертификат
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        textAlign="center"
                        sx={{
                            marginTop: 2
                        }}
                    >
                        Та сертификатаа найзуудтайгаа хуваалцаж тэднийг ч бас мод тарихыг уриалаарай.
                    </Typography>
                </Box>
            </Slider>
        </Container>
    );
});
