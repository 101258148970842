import { Avatar, Card, Grid, Stack, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import { Bronze, Gold, Silver } from '../assets/svg';
import { dark, light } from '../theme/muiTheme';

type UserProps = {
    avatar: string;
    nickname: string;
    rank: number;
    treesCount: number;
    isMe?: boolean;
};

export const User = (props: UserProps): JSX.Element => {
    const {
        isMe = false,
        avatar,
        nickname,
        rank,
        treesCount
    } = props;

    return (
        <ThemeProvider theme={isMe ? dark : light}>
            <Card
                variant="outlined"
                sx={{
                    padding: '8px 16px',
                    backgroundColor: isMe
                        ? 'primary.main'
                        : 'background.paper'
                }}
            >
                <Grid
                    columnSpacing={1}
                    alignItems="center"
                    container
                >
                    <Grid xs item>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing="12px"
                        >
                            {rank === 1 && (
                                <SvgIcon
                                    component={Gold}
                                    inheritViewBox
                                />
                            )}
                            {rank === 2 && (
                                <SvgIcon
                                    component={Silver}
                                    inheritViewBox
                                />
                            )}
                            {rank === 3 && (
                                <SvgIcon
                                    component={Bronze}
                                    inheritViewBox
                                />
                            )}
                            {[1, 2, 3].find(value => value === rank) === undefined && (
                                <Typography
                                    variant="subtitle1"
                                    textAlign="center"
                                    fontWeight={700}
                                    sx={{
                                        width: 24
                                    }}
                                >
                                    {rank}
                                </Typography>
                            )}
                            <Avatar
                                src={`/static/images/avatars/${avatar}.png`}
                                sx={{
                                    width: 48,
                                    height: 48,
                                    backgroundColor: '#f0f0f0'
                                }}
                            />
                            <div>
                                <Typography variant="subtitle1">
                                    {nickname}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.disabled"
                                >
                                    {treesCount} мод
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
            </Card>
        </ThemeProvider>
    );
};