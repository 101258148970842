import { ApolloError, gql, MutationFunction, MutationResult, QueryResult } from '@apollo/client';
import { Mutation, Query } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Card, Container, Dialog, InputAdornment, Skeleton, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { default as Animation } from 'lottie-react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Success } from '../assets/lottie';
import { Gift } from '../assets/svg';
import { PageHeader } from '../components/PageHeader';
import { createUseState } from '../components/UseState';
import { CurrentUserFields } from '../fragments/current-user-fields';
import { withUser } from '../hoc/withUser';
import { changeThemeColor, getValidationErrorMessage, parseErrors } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors } from '../redux/app/reducer';
import { ValidationError } from '../types';

const UseState = createUseState<ValidationError[]>();

const CURRENT_USER = gql`
    query CurrentUser {
        currentUser {
            drops
        }
    }
`;

const USER = gql`
    query User($id: String!) {
        user(id: $id) {
            id
            avatar
            nickname
        }
    }
`;

const CREATE_GIFT = gql`
    ${CurrentUserFields}
    mutation CreateGift(
        $drops: Int
        $receiverId: String
    ) {
        createGift(input: {
            drops: $drops
            receiverId: $receiverId
        }) {
            sender {
                ...CurrentUserFields
            }
        }
    }
`;

type SendGiftProps = {
    setColors: (colors: Colors) => void;
};

const mapDispatchToProps = {
    setColors
};

export const SendGift = connect(null, mapDispatchToProps)(withUser<SendGiftProps>(({ setColors }): JSX.Element => {
    const { userId } = useParams();

    const [
        open,
        setOpen
    ] = useState(false);
    const [
        drops,
        setDrops
    ] = useState<number | null>(null);
    const [
        user,
        setUser
    ] = useState({
        drops: 0
    });
    const [
        receiver,
        setReceiver
    ] = useState({
        id: '',
        avatar: '',
        nickname: ''
    });

    useEffect(() => {
        changeThemeColor('#fff');
        setColors({
            top: '#fff',
            bottom: '#fff'
        });
    }, []);

    return (
        <Container maxWidth="xs">
            <Dialog
                open={open}
                maxWidth="xs"
                fullWidth
            >
                <Box padding={2}>
                    <Animation
                        animationData={Success}
                        loop={false}
                        style={{
                            margin: '0px auto',
                            height: 64
                        }}
                    />
                    <Typography
                        variant="h6"
                        textAlign="center"
                        sx={{
                            marginTop: 1
                        }}
                    >
                        Амжилттай
                    </Typography>
                    <Card
                        elevation={0}
                        sx={{
                            marginTop: 2,
                            width: '100%',
                            padding: '8px 12px',
                            backgroundColor: '#f0f0f0'
                        }}
                    >
                        <Stack
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                            spacing={1}
                        >
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                            >
                                <Avatar
                                    src={`/static/images/avatars/${receiver.avatar}.png`}
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }}
                                />
                                <Typography variant="subtitle1">
                                    {receiver.nickname}
                                </Typography>
                            </Stack>
                            <Typography
                                variant="subtitle1"
                                color="primary"
                            >
                                +{drops}л
                            </Typography>
                        </Stack>
                    </Card>
                    <Button
                        component={Link}
                        to="/home"
                        size="large"
                        disableElevation
                        fullWidth
                        sx={{
                            marginTop: 1
                        }}
                    >
                        Нүүр хуудас
                    </Button>
                </Box>
            </Dialog>
            <PageHeader
                color="#fff"
                dark={false}
                closeButtonProps={{
                    component: Link,
                    to: '/friends'
                }}
            />
            <Box
                style={{
                    paddingTop: 64
                }}
            />
            <Box padding={2}>
                <Stack
                    alignItems="center"
                    spacing={1}
                    sx={{
                        fontSize: 60
                    }}
                >
                    <SvgIcon
                        component={Gift}
                        fontSize="inherit"
                        inheritViewBox
                    />
                    <Typography
                        variant="h6"
                        textAlign="center"
                    >
                        Бэлэг илгээх
                    </Typography>
                </Stack>
                <Query
                    fetchPolicy="no-cache"
                    query={USER}
                    variables={{
                        id: userId
                    }}
                    onError={(err: ApolloError) => {
                        //
                    }}
                    onCompleted={({ user }: any) => {
                        setReceiver(user);
                    }}
                >
                    {({ loading }: QueryResult) => (
                        <Card
                            variant="outlined"
                            sx={{
                                padding: '8px 12px',
                                marginTop: 4
                            }}
                        >
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                            >
                                {loading
                                    ?
                                    <Skeleton
                                        variant="circular"
                                        width={40}
                                        height={40}
                                    />
                                    :
                                    <Avatar
                                        src={`/static/images/avatars/${receiver.avatar}.png`}
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#f0f0f0'
                                        }}
                                    />
                                }
                                {loading
                                    ?
                                    <Skeleton
                                        variant="text"
                                        width={160}
                                        sx={{
                                            fontSize: 16
                                        }}
                                    />
                                    :
                                    <Typography variant="subtitle1">
                                        {receiver.nickname}
                                    </Typography>
                                }
                            </Stack>
                        </Card>
                    )}
                </Query>
                <UseState defaultValue={[]}>
                    {(errors, setErrors) => (
                        <Mutation
                            mutation={CREATE_GIFT}
                            variables={{
                                receiverId: userId,
                                drops
                            }}
                            onError={(err: ApolloError) => {
                                if (err.message === 'Bad Request Exception') {
                                    setErrors(parseErrors(err));
                                }
                            }}
                            onCompleted={({ createGift }: any) => {
                                setUser(createGift.sender);
                                setOpen(true);
                            }}
                        >
                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        label="Бэлэглэх усны хэмжээ"
                                        name="drops"
                                        value={drops || ''}
                                        onChange={e => setDrops(parseInt(e.target.value))}
                                        error={errors.some(({ property }) => property === 'drops')}
                                        helperText={getValidationErrorMessage(errors, 'drops')}
                                        fullWidth
                                        inputProps={{
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*'
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    литр
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <Query
                                        fetchPolicy="no-cache"
                                        query={CURRENT_USER}
                                        onError={(err: ApolloError) => {
                                            //
                                        }}
                                        onCompleted={({ currentUser }: any) => {
                                            setUser(currentUser);
                                        }}
                                    >
                                        {({ loading }: QueryResult) => (
                                            <div>
                                                {loading
                                                    ?
                                                    <Stack alignItems="flex-end">
                                                        <Skeleton
                                                            variant="text"
                                                            width={170}
                                                            sx={{
                                                                fontSize: 14
                                                            }}
                                                        />
                                                    </Stack>
                                                    :
                                                    <Typography
                                                        variant="body2"
                                                        color="text.disabled"
                                                        textAlign="right"
                                                    >
                                                        Боломжит үлдэгдэл: {user.drops}л
                                                    </Typography>
                                                }
                                            </div>
                                        )}
                                    </Query>
                                    <LoadingButton
                                        loading={loading}
                                        disabled={drops === null || drops > user.drops}
                                        variant="contained"
                                        size="large"
                                        disableElevation
                                        fullWidth
                                        onClick={() => {
                                            setErrors([]);
                                            mutate();
                                        }}
                                        sx={{
                                            marginTop: 2
                                        }}
                                    >
                                        Илгээх
                                    </LoadingButton>
                                </div>
                            )}
                        </Mutation>
                    )}
                </UseState>
            </Box>
        </Container>
    );
}));
