import { ApolloError, gql, MutationFunction } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { QuestionMark } from '@mui/icons-material';
import { Avatar, Box, CardActionArea, Typography } from '@mui/material';
import { default as Animation } from 'lottie-react';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { default as uniqid } from 'uniqid';
import { Confetti } from '../assets/lottie';
import { animateCSS } from '../lib/helpers';

const CLAIM_REWARD = gql`
    mutation ClaimReward($id: String) {
        claimReward(input: {
            id: $id
        })
    }
`;

type BubbleProps = {
    charges: number;
    onChangeCharges: (charges: number) => void;
    id: string;
    drops: number;
    onError: (err: ApolloError) => void;
    onClaim: (drops: number) => void;
};

export const Bubble = (props: BubbleProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const lottie = useRef<any>();

    const {
        charges,
        onChangeCharges,
        id,
        drops,
        onError,
        onClaim
    } = props;

    const [
        selector
    ] = useState(uniqid());
    const [
        popped,
        setPopped
    ] = useState(false);

    useEffect(() => {
        popped && lottie.current?.goToAndPlay(0, true);
    }, [popped]);

    return (
        <Mutation
            mutation={CLAIM_REWARD}
            variables={{
                id
            }}
            onError={(err: ApolloError) => {
                setTimeout(() => animateCSS(`#${selector}`, 'fadeOutUp').then(() => {
                    enqueueSnackbar(err.message, {
                        variant: 'error'
                    });
                    onError(err);
                    onChangeCharges(charges + 1);
                }), 2000);
            }}
            onCompleted={() => {
                setTimeout(() => animateCSS(`#${selector}`, 'fadeOutUp').then(() => {
                    onClaim(drops);
                }), 2000);
            }}
        >
            {(mutate: MutationFunction) => (
                <Box
                    className="animate__animated animate__zoomIn animate__faster"
                    sx={{
                        position: 'relative',
                        width: 64,
                        height: 64
                    }}
                >
                    <Animation
                        lottieRef={lottie}
                        autoplay={false}
                        loop={false}
                        animationData={Confetti}
                        style={{
                            position: 'absolute',
                            inset: 0
                        }}
                    />
                    {popped === false && (
                        <CardActionArea
                            className="animate__animated animate__pulse animate__infinite animate__slow"
                            disabled={charges === 0}
                            onClick={() => {
                                onChangeCharges(charges - 1);
                                setPopped(true);
                                mutate();
                            }}
                            sx={{
                                position: 'absolute',
                                inset: 0,
                                borderRadius: '50%',
                                opacity: charges === 0 ? 0.4 : 1
                            }}
                        >
                            <Avatar
                                src="/static/images/empty-bubble.png"
                                sx={{
                                    width: 64,
                                    height: 64
                                }}
                            />
                            <QuestionMark
                                sx={{
                                    position: 'absolute',
                                    inset: '50% auto auto 50%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                            />
                        </CardActionArea>
                    )}
                    {popped && (
                        <Box
                            sx={{
                                position: 'absolute',
                                inset: '50% auto auto 50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            <div className="animate__animated animate__zoomIn animate__faster">
                                <Box
                                    className="animate__animated"
                                    id={selector}
                                    sx={{
                                        '&:not(.animate__animated)': {
                                            opacity: 0
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        textAlign="center"
                                    >
                                        +{drops}л
                                    </Typography>
                                </Box>
                            </div>
                        </Box>
                    )}
                </Box>
            )}
        </Mutation>
    );
};