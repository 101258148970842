import { Avatar, Box, Card, CardActionArea, Radio, Stack } from '@mui/material';

type RadioAvatarProps = {
    src: string;
    checked: boolean;
    onChangeValue: (checked: boolean) => void;
};

export const RadioAvatar = (props: RadioAvatarProps): JSX.Element => {
    const {
        src,
        checked,
        onChangeValue
    } = props;

    return (
        <Card
            variant="outlined"
            sx={{
                borderColor: checked
                    ? 'primary.main'
                    : 'divider',
                backgroundColor: checked
                    ? '#0c4da21a'
                    : '#fff'
            }}
        >
            <CardActionArea
                onClick={() => {
                    onChangeValue(true);
                }}
            >
                <Box padding={1}>
                    <Stack alignItems="center">
                        <Avatar
                            src={src}
                            sx={{
                                width: 64,
                                height: 64
                            }}
                        />
                        <Radio
                            size="small"
                            checked={checked}
                            disableRipple
                            sx={{
                                padding: 0,
                                color: 'text.disabled'
                            }}
                        />
                    </Stack>
                </Box>
            </CardActionArea>
        </Card>
    );
};