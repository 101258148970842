import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Box, CircularProgress, Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { User } from '../assets/svg';
import { PageHeader } from '../components/PageHeader';
import { Tree } from '../components/Tree';
import { UserDetails } from '../components/UserDetails';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';

const TREES = gql`
    query Trees {
        currentUser {
            trees(
                sortBy: "createdAt"
                sortOrder: "desc"
            ) {
                edges {
                    id
                    progress
                    type {
                        name
                        code
                    }
                }
            }
        }
    }
`;

type ProfileProps = {
    setColors: (colors: Colors) => void;
    insets: Insets;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setColors
};

export const Profile = connect(mapStateToProps, mapDispatchToProps)(withUser<ProfileProps>((props): JSX.Element => {
    const {
        setColors,
        insets
    } = props;

    useEffect(() => {
        changeThemeColor('#503ebf');
        setColors({
            top: '#503ebf',
            bottom: '#fff'
        });
    }, []);

    return (
        <Container maxWidth="xs">
            <PageHeader
                svgIcon={User}
                title="Профайл"
                color="#503ebf"
                closeButtonProps={{
                    component: Link,
                    to: '/home'
                }}
            />
            <Box
                sx={{
                    position: 'fixed',
                    inset: '0px auto auto 50%',
                    width: '100%',
                    maxWidth: 444,
                    transform: 'translateX(-50%)',
                    height: insets.top + 150,
                    backgroundColor: '#503ebf'
                }}
            />
            <Box
                style={{
                    paddingTop: 64
                }}
            />
            <Box
                sx={{
                    position: 'relative',
                    padding: 2
                }}
            >
                <Box marginBottom={1}>
                    <UserDetails />
                </Box>
                <Query
                    fetchPolicy="no-cache"
                    query={TREES}
                    onError={(err: ApolloError) => {
                        //
                    }}
                >
                    {({ data, loading }: QueryResult) => (
                        <div>
                            {loading && (
                                <Box textAlign="center">
                                    <CircularProgress />
                                </Box>
                            )}
                            {data && (
                                <Grid
                                    spacing={1}
                                    container
                                >
                                    {data.currentUser.trees.edges.map(({ id, progress, type }: any) => (
                                        <Grid
                                            key={id}
                                            xs={6}
                                            item
                                        >
                                            <Tree
                                                id={id}
                                                name={type.name}
                                                code={type.code}
                                                progress={progress}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </div>
                    )}
                </Query>
            </Box>
        </Container>
    );
}));
