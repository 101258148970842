import { Box } from '@mui/material';
import { default as mix } from 'mix-css-color';

type HorizontalProgressProps = {
    value: number;
};

export const HorizontalProgress = (props: HorizontalProgressProps): JSX.Element => {
    const { value } = props;

    return (
        <Box
            sx={{
                display: 'block',
                padding: '2px',
                width: '100%',
                height: 8,
                borderRadius: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.6)'
            }}
        >
            <Box
                sx={{
                    display: 'block',
                    width: `${value}%`,
                    height: 4,
                    borderRadius: 2,
                    backgroundColor: value <= 50 ? mix('#fbcc34', '#d12a30', value * 2).hex : mix('#13a456', '#fbcc34', 2 * value - 100).hex
                }}
            />
        </Box>
    );
};