import { ApolloError } from '@apollo/client';
import { Position, ValidationError } from '../types';

const breakPoints = [
    {
        lvl: 1,
        value: 10
    },
    {
        lvl: 2,
        value: 20
    },
    {
        lvl: 3,
        value: 30
    },
    {
        lvl: 4,
        value: 40
    },
    {
        lvl: 5,
        value: 50
    },
    {
        lvl: 6,
        value: 60
    },
    {
        lvl: 7,
        value: 70
    },
    {
        lvl: 8,
        value: 80
    },
    {
        lvl: 9,
        value: 90
    },
    {
        lvl: 10,
        value: 100
    }
];

/**
 * Return message from validation error
 *
 * @param {any[]} errors
 * @param {string} prop
 * @returns {string|undefined}
 */
export const getValidationErrorMessage = (errors: ValidationError[], prop: string): string | undefined => {
    const props = prop.split('.');
    const err = props.reduce<ValidationError[]>((errors: ValidationError[], value: string) => value === props[props.length - 1] ? errors : errors.find(({ property }) => property === value)?.children || [], errors).find(({ property }) => property === props[props.length - 1]);
    return err?.constraints[Object.keys(err?.constraints)[0]];
};

/**
 * Return validation errors
 *
 * @param {ApolloError} err
 * @returns {ValidationError[]}
 */
export const parseErrors = (err: ApolloError): ValidationError[] => {
    return err.graphQLErrors.reduce((messages: ValidationError[], error: any) => [
        ...messages,
        ...error.extensions.response.message
    ], []);
};

/**
 * Return random number between given numbers
 *
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const randomBetween = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Return tree level from progress
 *
 * @param {number} progress
 * @returns {number}
 */
export const level = (progress: number): number => {
    return breakPoints.find(({ value }) => progress <= value)?.lvl || 10;
};

/**
 * Return tree level from progress
 *
 * @param {string} code
 * @param {number} progress
 * @returns {unknown}
 */
export const generateTreeJson = (code: 'aspen' | 'elm' | 'pine' | 'willow' | 'sea-buckthorn', progress: number): string => {
    return `${code}/${level(progress)}.json`;
};

/**
 * Change dom theme color
 *
 * @param {string} color
 * @returns {void}
 */
export const changeThemeColor = (color: string): void => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', color);
};

/**
 * Animate CSS
 *
 * @param {string} element
 * @param {string} animation
 * @param {string} prefix
 * @returns {Promise}
 */
export const animateCSS = (element: string, animation: string, prefix: string = 'animate__'): Promise<boolean> => new Promise((resolve) => {
    const animations = animation.split(' ').map(animation => `${prefix}${animation}`);
    const node = document.querySelector(element);
    if (node === null) {
        return resolve(true);
    }
    node?.classList.add(`${prefix}animated`, ...animations);
    node?.addEventListener('animationend', (event: Event) => {
        event.stopPropagation();
        node.classList.remove(`${prefix}animated`, ...animations);
        resolve(true);
    }, { once: true });
});

/**
 * Return current time hours
 *
 * @param {Date} now
 * @returns {number}
 */
export const getCurrentHours = (now: Date = new Date()): number => {
    return now.getHours();
};

/**
 * Return HTML element's current position
 *
 * @param {string} id
 * @returns {Position}
 */
export const getPosition = (id: string): Position => {
    const box = document.getElementById(id)?.getBoundingClientRect();
    if (box === undefined) {
        return {
            x: undefined,
            y: undefined
        };
    }
    return {
        x: (box.left + box.right) / window.innerWidth * 50,
        y: (box.top + box.bottom) / window.innerHeight * 50
    };
};