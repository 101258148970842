import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Container, Grid, Stack } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { RadioAvatar } from '../components/RadioAvatar';
import { CurrentUserFields } from '../fragments/current-user-fields';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { setUser } from '../redux/auth/actions';

const UPDATE_AVATAR = gql`
    ${CurrentUserFields}
    mutation UpdateAvatar($avatar: String) {
        updateAvatar(input: {
            avatar: $avatar
        }) {
            ...CurrentUserFields
        }
    }
`;

type ChangeAvatarProps = {
    setColors: (colors: Colors) => void;
    setUser: (user: any) => void;
    insets: Insets;
    user: any;
};

const mapDispatchToProps = {
    setColors,
    setUser
};

export const ChangeAvatar = connect(null, mapDispatchToProps)(withUser<ChangeAvatarProps>((props): JSX.Element => {
    const redirect = useNavigate();

    const { enqueueSnackbar } = useSnackbar();
    const {
        setColors,
        setUser,
        user
    } = props;

    useEffect(() => {
        changeThemeColor('#fff');
        setColors({
            top: '#fff',
            bottom: '#fff'
        });
    }, []);

    return (
        <Mutation
            mutation={UPDATE_AVATAR}
            variables={{

            }}
            onError={(err: ApolloError) => {
                enqueueSnackbar(err.message, {
                    variant: 'error'
                });
            }}
            onCompleted={({ updateAvatar }: any) => {
                setUser(updateAvatar);
                redirect('/edit/profile');
            }}
        >
            {(mutate: MutationFunction, { loading }: MutationResult) => (
                <Container maxWidth="xs">
                    <PageHeader
                        color="#fff"
                        dark={false}
                        closeButtonProps={{
                            component: Link,
                            to: '/edit/profile'
                        }}
                    />
                    <Box
                        style={{
                            paddingTop: 64
                        }}
                    />
                    <Formik
                        initialValues={{
                            avatar: user.avatar
                        }}
                        onSubmit={values => {
                            mutate({
                                variables: values
                            });
                        }}
                    >
                        {({ values, handleSubmit, getFieldHelpers }) => (
                            <form
                                onSubmit={handleSubmit}
                                autoComplete="off"
                                noValidate
                            >
                                <Box padding={2}>
                                    <Stack
                                        alignItems="center"
                                        sx={{
                                            marginBottom: 3
                                        }}
                                    >
                                        <Avatar
                                            src={`/static/images/avatars/${values.avatar}.png`}
                                            sx={{
                                                width: 96,
                                                height: 96,
                                                backgroundColor: '#4db6ac'
                                            }}
                                        />
                                    </Stack>
                                    <Grid
                                        spacing={1}
                                        container
                                    >
                                        {['1', '2', '3', '4', '5', '6', '7', '8', '9'].map(number => (
                                            <Grid
                                                key={number}
                                                xs={4}
                                                item
                                            >
                                                <RadioAvatar
                                                    src={`/static/images/avatars/${number}.png`}
                                                    checked={number === values.avatar}
                                                    onChangeValue={() => {
                                                        getFieldHelpers('avatar').setValue(number);
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <LoadingButton
                                        loading={loading}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        type="submit"
                                        disableElevation
                                        fullWidth
                                        sx={{
                                            marginTop: 2
                                        }}
                                    >
                                        Хадгалах
                                    </LoadingButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            )}
        </Mutation>
    );
}));
