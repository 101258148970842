import { Box, Button, Stack, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { Close } from '../assets/svg';
import { Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';
import { dark as darkTheme, light as lightTheme } from '../theme/muiTheme';

type CloseButtonProps = {
    component?: React.ElementType;
    to?: string;
    onClick?: () => void;
};

type PageHeaderProps = {
    insets: Insets;
    svgIcon?: React.ElementType;
    title?: string;
    description?: React.ReactNode;
    color: string;
    closeButtonProps?: CloseButtonProps;
    dark?: boolean;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

export const PageHeader = connect(mapStateToProps)((props: PageHeaderProps): JSX.Element => {
    const {
        insets,
        svgIcon,
        title,
        description,
        closeButtonProps,
        color,
        dark = true
    } = props;

    return (
        <ThemeProvider theme={dark ? darkTheme : lightTheme}>
            <Box
                sx={{
                    position: 'fixed',
                    inset: `${insets.top}px auto auto`,
                    width: '100%',
                    maxWidth: 444,
                    zIndex: 999,
                    padding: '12px 16px',
                    backgroundColor: color
                }}
            >
                <Stack
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                    >
                        {svgIcon && (
                            <SvgIcon
                                component={svgIcon}
                                inheritViewBox
                            />
                        )}
                        <div>
                            {title && (
                                <Typography
                                    variant="h6"
                                    color="text.primary"
                                >
                                    {title}
                                </Typography>
                            )}
                            {description && (
                                <Box color="text.secondary">
                                    <Typography
                                        variant="body2"
                                        noWrap
                                    >
                                        {description}
                                    </Typography>
                                </Box>
                            )}
                        </div>
                    </Stack>
                    <Box color="text.primary">
                        <Button
                            {...closeButtonProps}
                            color="inherit"
                            sx={{
                                minWidth: 0,
                                padding: '12px 20px',
                                fontSize: 16,
                                borderRadius: 16,
                                backgroundColor: 'rgba(0, 0, 0, 0.15) !important'
                            }}
                        >
                            <SvgIcon
                                component={Close}
                                fontSize="inherit"
                                inheritViewBox
                            />
                        </Button>
                    </Box>
                </Stack>
            </Box>
        </ThemeProvider>
    );
});
