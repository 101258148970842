import { Add } from '@mui/icons-material';
import { Avatar, Box, Button, CardActionArea, Skeleton, Stack, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../redux/configureStore';

type UserDropsProps = {
    loading: boolean;
    user: any;
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

export const UserDrops = connect(mapStateToProps)((props: UserDropsProps): JSX.Element => {
    const {
        loading,
        user
    } = props;

    return (
        <Box
            sx={{
                position: 'absolute',
                inset: '12px auto auto 16px',
                zIndex: 999
            }}
        >
            <CardActionArea
                component={Link}
                to="/profile"
                sx={{
                    width: 'auto',
                    minWidth: 180,
                    padding: '2px',
                    paddingRight: '26px',
                    border: '3px solid #fff',
                    borderRadius: 100,
                    backgroundColor: 'rgba(255, 255, 255, 0.6)'
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    {loading
                        ?
                        <Skeleton
                            variant="circular"
                            width={40}
                            height={40}
                            sx={{
                                border: '3px solid #fff'
                            }}
                        />
                        :
                        <Avatar
                            src={`/static/images/avatars/${user.avatar}.png`}
                            sx={{
                                width: 40,
                                height: 40,
                                border: '3px solid #fff',
                                backgroundColor: '#4db6ac'
                            }}
                        />
                    }
                    {loading
                        ?
                        <Box flex={1}>
                            <Skeleton
                                variant="text"
                                width="100%"
                                sx={{
                                    fontSize: 20
                                }}
                            />
                        </Box>
                        :
                        <Box>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                lineHeight={1}
                                fontSize={12}
                            >
                                {user.nickname}
                            </Typography>
                            <Typography
                                variant="h6"
                                color="#384d61"
                                lineHeight={1}
                                sx={{
                                    marginTop: '4px'
                                }}
                            >
                                {user.drops} литр
                            </Typography>
                        </Box>
                    }
                </Stack>
            </CardActionArea>
            <Button
                component={Link}
                to="/shop"
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                sx={{
                    minWidth: 0,
                    position: 'absolute',
                    inset: '50% 0 auto auto',
                    padding: '6px',
                    transform: 'translate(50%, -50%)',
                    zIndex: 999
                }}
            >
                <Add />
            </Button>
        </Box>
    );
});