import type { } from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';

export const light = createTheme({
    palette: {
        primary: {
            main: '#0c4da2'
        },
        info: {
            main: '#21b8e8',
            contrastText: '#fff'
        },
        success: {
            main: '#13a456'
        },
        error: {
            main: '#ec6264'
        }
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        h1: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h2: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h3: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h4: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h5: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h6: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        subtitle1: {
            fontFamily: 'Nunito',
            fontWeight: 700,
            lineHeight: 1.5
        },
        subtitle2: {
            fontFamily: 'Nunito',
            fontWeight: 700,
            lineHeight: 1.43
        },
        body1: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        body2: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        button: {
            fontFamily: 'Nunito',
            fontWeight: 700,
            textTransform: 'uppercase'
        },
        caption: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        overline: {
            fontFamily: 'Nunito',
            fontWeight: 400
        }
    },
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true
            }
        },
        MuiBadge: {
            defaultProps: {
                color: 'error'
            },
            styleOverrides: {
                badge: {
                    fontWeight: 700
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: 14
                }
            }
        },
        MuiContainer: {
            defaultProps: {
                disableGutters: true
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.default
                })
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontFamily: 'Nunito',
                    fontWeight: 700
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: '4px'
                },
                bar: {
                    borderRadius: '4px'
                },
                colorPrimary: {
                    backgroundColor: '#e9e9e9'
                }
            }
        }
    }
});

export const dark = createTheme({
    ...light,
    palette: {
        mode: 'dark',
        primary: {
            main: '#0c4da2'
        },
        info: {
            main: '#21b8e8',
            contrastText: '#fff'
        },
        success: {
            main: '#13a456'
        },
        error: {
            main: '#ec6264'
        }
    }
});