import { Box } from '@mui/material';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { App } from './layouts/App';
import { AboutUs } from './pages/AboutUs';
import { AddFriend } from './pages/AddFriend';
import { ChangeAvatar } from './pages/ChangeAvatar';
import { ChoosePlace } from './pages/ChoosePlace';
import { ChooseType } from './pages/ChooseType';
import { CreateAvatar } from './pages/CreateAvatar';
import { CreateNickname } from './pages/CreateNickname';
import { EditProfile } from './pages/EditProfile';
import { Friend } from './pages/Friend';
import { FriendRequests } from './pages/FriendRequests';
import { Friends } from './pages/Friends';
import { Help } from './pages/Help';
import { Home } from './pages/Home';
import { Intro } from './pages/Intro';
import { LeaderboardFriends } from './pages/LeaderboardFriends';
import { LeaderboardGlobal } from './pages/LeaderboardGlobal';
import { Map } from './pages/Map';
import { NotFound } from './pages/NotFound';
import { Notifications } from './pages/Notifications';
import { NotificationsParent } from './pages/NotificationsParent';
import { PlantSeed } from './pages/PlantSeed';
import { Profile } from './pages/Profile';
import { SendGift } from './pages/SendGift';
import { Shop } from './pages/Shop';
import { Steal } from './pages/Steal';
import { Transactions } from './pages/Transactions';
import { Tree } from './pages/Tree';
import { Colors, Insets } from './redux/app/reducer';
import { RootState } from './redux/configureStore';

type RouterProps = {
    colors: Colors;
    insets: Insets;
};

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets,
    colors: app.colors
});

export const Router = connect(mapStateToProps)((props: RouterProps): JSX.Element => {
    const {
        insets,
        colors
    } = props;

    return (
        <Fragment>
            <Box
                sx={{
                    minHeight: '100vh',
                    paddingTop: `${insets.top}px`,
                    paddingBottom: `${insets.bottom}px`
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        inset: '0px auto auto 50%',
                        width: '100%',
                        maxWidth: 444,
                        transform: 'translateX(-50%)',
                        height: insets.top,
                        backgroundColor: colors.top,
                        zIndex: 999
                    }}
                />
                <Box
                    sx={{
                        position: 'fixed',
                        inset: 'auto auto 0px 50%',
                        width: '100%',
                        maxWidth: 444,
                        transform: 'translateX(-50%)',
                        height: insets.bottom,
                        backgroundColor: colors.bottom,
                        zIndex: 999
                    }}
                />
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <App />
                            }
                        >
                            <Route
                                index
                                element={
                                    <Intro />
                                }
                            />
                            <Route
                                path="home"
                                element={
                                    <Home />
                                }
                            />
                            <Route
                                path="about-us"
                                element={
                                    <AboutUs />
                                }
                            />
                            <Route
                                path="add/friend"
                                element={
                                    <AddFriend />
                                }
                            />
                            <Route
                                path="change/avatar"
                                element={
                                    <ChangeAvatar />
                                }
                            />
                            <Route
                                path="choose/place/:typeId"
                                element={
                                    <ChoosePlace />
                                }
                            />
                            <Route
                                path="choose/type"
                                element={
                                    <ChooseType />
                                }
                            />
                            <Route
                                path="create/avatar"
                                element={
                                    <CreateAvatar />
                                }
                            />
                            <Route
                                path="create/nickname"
                                element={
                                    <CreateNickname />
                                }
                            />
                            <Route
                                path="edit/profile"
                                element={
                                    <EditProfile />
                                }
                            />
                            <Route
                                path="friend/:id"
                                element={
                                    <Friend />
                                }
                            />
                            <Route
                                path="friends"
                                element={
                                    <Friends />
                                }
                            >
                                <Route
                                    index
                                    element={
                                        <LeaderboardFriends />
                                    }
                                />
                                <Route
                                    path="leaderboard/global"
                                    element={
                                        <LeaderboardGlobal />
                                    }
                                />
                                <Route
                                    path="requests"
                                    element={
                                        <FriendRequests />
                                    }
                                />
                            </Route>
                            <Route
                                path="help"
                                element={
                                    <Help />
                                }
                            />
                            <Route
                                path="map"
                                element={
                                    <Map />
                                }
                            />
                            <Route
                                path="notifications"
                                element={
                                    <NotificationsParent />
                                }
                            >
                                <Route
                                    index
                                    element={
                                        <Notifications />
                                    }
                                />
                                <Route
                                    path="transactions"
                                    element={
                                        <Transactions />
                                    }
                                />
                            </Route>
                            <Route
                                path="plant/seed"
                                element={
                                    <PlantSeed />
                                }
                            />
                            <Route
                                path="profile"
                                element={
                                    <Profile />
                                }
                            />
                            <Route
                                path="send/gift/:userId"
                                element={
                                    <SendGift />
                                }
                            />
                            <Route
                                path="shop"
                                element={
                                    <Shop />
                                }
                            />
                            <Route
                                path="steal"
                                element={
                                    <Steal />
                                }
                            />
                            <Route
                                path="tree/:id"
                                element={
                                    <Tree />
                                }
                            />
                            <Route
                                path="*"
                                element={
                                    <NotFound />
                                }
                            />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Box>
        </Fragment>
    );
});
