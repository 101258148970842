import { useState } from "react";

type UseHoldOptions = {
    onHoldStart: () => void;
    onHoldEnd: () => void;
    onClick: () => void;
    delay?: number;
};

export function useHold(options: UseHoldOptions) {
    const {
        delay = 300,
        onHoldStart,
        onHoldEnd,
        onClick
    } = options;

    const [
        startTime,
        setStartTime
    ] = useState(0);
    const [
        timeoutId,
        setTimeoutId
    ] = useState(0);

    const start = (e: any) => {
        setStartTime(Date.now());
        setTimeoutId(window.setTimeout(() => {
            onHoldStart();
        }, delay));
    };

    const end = (e: any) => {
        clearTimeout(timeoutId);
        Date.now() - startTime < delay
            ? onClick()
            : onHoldEnd();
    };

    return {
        onTouchStart: (e: any) => start(e),
        onTouchEnd: (e: any) => end(e)
    };
};