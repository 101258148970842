import { useCallback, useState } from 'react';
import { default as Particles } from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { Engine } from 'tsparticles-engine';
import { v4 as uuidv4 } from 'uuid';
import { Position } from '../types';

type ConfettiProps = {
    position: Position;
};

export const Confetti = (props: ConfettiProps): JSX.Element | null => {
    const { position } = props;

    const [
        id
    ] = useState(uuidv4());

    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);

    if (position.x === undefined || position.y === undefined) {
        return null;
    }

    return (
        <Particles
            id={id}
            init={particlesInit}
            options={{
                fullScreen: {
                    zIndex: 1
                },
                particles: {
                    number: {
                        value: 1
                    },
                    color: {
                        value: [
                            '#ed1c24',
                            '#fbcc34',
                            '#7ac943',
                            '#06aae9'
                        ]
                    },
                    shape: {
                        type: [
                            'circle',
                            'square',
                            'triangle'
                        ]
                    },
                    opacity: {
                        value: 1,
                        animation: {
                            enable: true,
                            minimumValue: 0,
                            speed: 2,
                            startValue: 'max',
                            destroy: 'min'
                        }
                    },
                    size: {
                        value: 4,
                        random: {
                            enable: true,
                            minimumValue: 2
                        }
                    },
                    links: {
                        enable: false
                    },
                    life: {
                        duration: {
                            sync: true,
                            value: 5
                        },
                        count: 1
                    },
                    move: {
                        enable: true,
                        gravity: {
                            enable: true,
                            acceleration: 31
                        },
                        speed: {
                            min: 10,
                            max: 40
                        },
                        decay: 0.1,
                        direction: 'none',
                        straight: false,
                        outModes: {
                            default: 'destroy',
                            top: 'none'
                        }
                    },
                    rotate: {
                        value: {
                            min: 0,
                            max: 360
                        },
                        direction: 'random',
                        animation: {
                            enable: true,
                            speed: 60
                        }
                    },
                    wobble: {
                        distance: 30,
                        enable: true,
                        move: true,
                        speed: {
                            min: -15,
                            max: 15
                        }
                    }
                },
                emitters: {
                    position: {
                        x: position.x,
                        y: position.y
                    },
                    life: {
                        count: 1,
                        duration: 0.1
                    },
                    rate: {
                        delay: 0.1,
                        quantity: 150
                    },
                    size: {
                        width: 0,
                        height: 0
                    }
                }
            }}
        />
    );
};