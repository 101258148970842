import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Box, CircularProgress, Divider } from '@mui/material';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Empty } from '../components/Empty';
import { Transaction } from '../components/Transaction';
import { withUser } from '../hoc/withUser';

const TRANSACTIONS = gql`
    query Transactions(
        $skip: Int
        $take: Int
    ) {
        transactions(
            skip: $skip
            take: $take
            sortBy: "createdAt"
            sortOrder: "desc"
        ) {
            count
            edges {
                id
                type
                method
                drops
                balance
                createdAt
            }
        }
    }
`;

export const Transactions = withUser((): JSX.Element => {
    const [
        skip,
        setSkip
    ] = useState(0);
    const [
        transactions,
        setTransactions
    ] = useState<any[]>([]);

    return (
        <Query
            fetchPolicy="no-cache"
            query={TRANSACTIONS}
            variables={{
                skip,
                take: 20
            }}
            onError={(err: ApolloError) => {
                //
            }}
            onCompleted={(data: any) => {
                setTransactions([
                    ...transactions,
                    ...data.transactions.edges
                ]);
            }}
        >
            {({ data, loading }: QueryResult) => (
                <div>
                    {transactions.map(({ id, type, method, drops, balance, createdAt }: any, index: number) => (
                        <div key={id}>
                            <Transaction
                                type={type}
                                method={method}
                                drops={drops}
                                balance={balance}
                                createdAt={createdAt}
                            />
                            {index !== transactions.length - 1 && (
                                <Divider />
                            )}
                        </div>
                    ))}
                    {data && (
                        <InView
                            style={{
                                height: Number(data.transactions.count !== transactions.length)
                            }}
                            onChange={visible => {
                                if (visible && data.transactions.count > transactions.length) {
                                    setSkip(transactions.length);
                                }
                            }}
                        />
                    )}
                    {loading && (
                        <Box padding={2}>
                            <Box textAlign="center">
                                <CircularProgress />
                            </Box>
                        </Box>
                    )}
                    {loading === false && transactions.length === 0 && (
                        <Empty
                            top={128}
                        />
                    )}
                </div>
            )}
        </Query>
    );
});