import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Add, Search } from '@mui/icons-material';
import { Avatar, Box, Button, CircularProgress, Container, debounce, InputAdornment, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserAdd } from '../assets/svg';
import { Empty } from '../components/Empty';
import { PageHeader } from '../components/PageHeader';
import { Stranger } from '../components/Stranger';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors } from '../redux/app/reducer';

const STRANGERS = gql`
    query Strangers(
        $search: String
        $skip: Int
        $take: Int
    ) {
        strangers(
            search: $search
            skip: $skip
            take: $take
            sortBy: "nickname"
            sortOrder: "asc"
        ) {
            count
            edges {
                id
                avatar
                nickname
                trees {
                    count
                }
            }
        }
    }
`;

type AddFriendProps = {
    setColors: (colors: Colors) => void;
};

const mapDispatchToProps = {
    setColors
};

export const AddFriend = connect(null, mapDispatchToProps)(withUser<AddFriendProps>(({ setColors }): JSX.Element => {
    const redirect = useNavigate();
    const [
        search,
        setSearch
    ] = useState('');
    const [
        skip,
        setSkip
    ] = useState(0);
    const [
        strangers,
        setStrangers
    ] = useState<any[]>([]);

    useEffect(() => {
        changeThemeColor('#fff');
        setColors({
            top: '#fff',
            bottom: '#fff'
        });
    }, []);

    return (
        <Container maxWidth="xs">
            <PageHeader
                color="#fff"
                dark={false}
                closeButtonProps={{
                    onClick: () => {
                        redirect(-1);
                    }
                }}
            />
            <Box
                style={{
                    paddingTop: 64
                }}
            />
            <Box padding={2}>
                <Stack alignItems="center">
                    <Avatar
                        sx={{
                            width: 80,
                            height: 80,
                            backgroundColor: '#f0f0f0'
                        }}
                    >
                        <SvgIcon
                            component={UserAdd}
                            fontSize="large"
                            inheritViewBox
                        />
                    </Avatar>
                    <Typography
                        variant="h6"
                        textAlign="center"
                        sx={{
                            marginTop: 1
                        }}
                    >
                        Найз нэмэх
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        textAlign="center"
                    >
                        Найзаа нэмээд найзаасаа ус<br />бэлгэнд аваарай
                    </Typography>
                </Stack>
                <Box marginTop={5}>
                    <TextField
                        placeholder="Нэр, утасны дугаараар хайх"
                        variant="outlined"
                        onChange={debounce(e => {
                            setStrangers([]);
                            setSkip(0);
                            setSearch(e.target.value);
                        }, 500)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        fullWidth
                    />
                    <Box marginTop={2}>
                        <Query
                            fetchPolicy="no-cache"
                            query={STRANGERS}
                            variables={{
                                search,
                                skip,
                                take: 20
                            }}
                            onError={(err: ApolloError) => {
                                //
                            }}
                            onCompleted={(data: any) => {
                                setStrangers([
                                    ...strangers,
                                    ...data.strangers.edges
                                ]);
                            }}
                        >
                            {({ data, loading }: QueryResult) => (
                                <Stack spacing={1}>
                                    {strangers.map(({ id, avatar, nickname, trees }: any) => (
                                        <div key={id}>
                                            <Stranger
                                                id={id}
                                                avatar={avatar}
                                                nickname={nickname}
                                                treesCount={trees.count}
                                            />
                                        </div>
                                    ))}
                                    {data && (
                                        <InView
                                            style={{
                                                height: Number(data.strangers.count !== strangers.length)
                                            }}
                                            onChange={visible => {
                                                if (visible && data.strangers.count > strangers.length) {
                                                    setSkip(strangers.length);
                                                }
                                            }}
                                        />
                                    )}
                                    {loading && (
                                        <Box textAlign="center">
                                            <CircularProgress />
                                        </Box>
                                    )}
                                    {loading === false && strangers.length === 0 && (
                                        <Empty
                                            description="Бүртгэлтэй хэрэглэгч олдсонгүй"
                                            top={344}
                                            action={
                                                <Button
                                                    variant="outlined"
                                                    color="inherit"
                                                    size="small"
                                                    startIcon={
                                                        <Add />
                                                    }
                                                    onClick={() => {
                                                        window.ReactNativeWebView?.postMessage(JSON.stringify({
                                                            type: 'INVITE_FRIEND'
                                                        }));
                                                    }}
                                                    sx={{
                                                        borderColor: 'rgba(0, 0, 0, 0.12)'
                                                    }}
                                                >
                                                    Найзаа урих
                                                </Button>
                                            }
                                        />
                                    )}
                                </Stack>
                            )}
                        </Query>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}));
