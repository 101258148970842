import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Add } from '@mui/icons-material';
import { Box, Button, Container, Tab, TabProps, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Users } from '../assets/svg';
import { PageHeader } from '../components/PageHeader';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors, Insets } from '../redux/app/reducer';
import { RootState } from '../redux/configureStore';

const FRIEND_REQUESTS = gql`
    query FriendRequests {
        friendRequests {
            count
        }
    }
`;

type FriendsProps = {
    setColors: (colors: Colors) => void;
    insets: Insets;
};

type StyledTabProps = TabProps & {
    component?: React.ElementType;
    to?: string;
};

const StyledTabs = styled(Tabs)({
    backgroundColor: '#4f345a',
    '& .MuiTabs-indicator': {
        height: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.38)'
    }
});

const StyledTab = styled(Tab)<StyledTabProps>({
    color: 'rgba(255, 255, 255, 0.6)',
    '&.Mui-selected': {
        color: '#fff'
    }
});

const mapStateToProps = ({ app }: RootState) => ({
    insets: app.insets
});

const mapDispatchToProps = {
    setColors
};

export const Friends = connect(mapStateToProps, mapDispatchToProps)(withUser((props: FriendsProps): JSX.Element => {
    const { pathname } = useLocation();
    const {
        setColors,
        insets
    } = props;

    const [
        tab,
        setTab
    ] = useState(pathname === '/friends' ? 0 : 1);

    useEffect(() => {
        changeThemeColor('#4f345a');
        setColors({
            top: '#4f345a',
            bottom: '#fff'
        });
    }, []);

    return (
        <Container maxWidth="xs">
            <PageHeader
                svgIcon={Users}
                title="Найзууд"
                color="#4f345a"
                closeButtonProps={{
                    component: Link,
                    to: '/home'
                }}
            />
            <Query
                fetchPolicy="no-cache"
                query={FRIEND_REQUESTS}
                onError={(err: ApolloError) => {
                    //
                }}
            >
                {({ data }: QueryResult) => (
                    <StyledTabs
                        value={tab}
                        onChange={(_, value) => setTab(value)}
                        sx={{
                            position: 'fixed',
                            inset: `${64 + insets.top}px auto auto`,
                            width: '100%',
                            maxWidth: 444,
                            zIndex: 999
                        }}
                    >
                        <StyledTab
                            component={Link}
                            label="Шилдгүүд"
                            to="/friends"
                        />
                        <StyledTab
                            component={Link}
                            label={data && data.friendRequests.count ? `Найзын хүсэлтүүд (${data?.friendRequests.count})` : 'Найзын хүсэлтүүд'}
                            to="/friends/requests"
                        />
                    </StyledTabs>
                )}
            </Query>
            <Box
                sx={{
                    position: 'fixed',
                    inset: `auto auto ${insets.bottom}px`,
                    zIndex: 999,
                    width: '100%',
                    maxWidth: 444,
                    padding: 1,
                    textAlign: 'center',
                    borderTop: theme => `1px solid ${theme.palette.divider}`,
                    backgroundColor: '#fff',
                    color: '#4f345a'
                }}
            >
                <Button
                    component={Link}
                    to="/add/friend"
                    color="inherit"
                    size="large"
                    startIcon={
                        <Add />
                    }
                >
                    Найз нэмэх
                </Button>
            </Box>
            <Box
                style={{
                    paddingTop: 112
                }}
            />
            <Outlet />
            <Box
                style={{
                    paddingTop: 58
                }}
            />
        </Container>
    );
}));
