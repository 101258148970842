import { ApolloError, gql, MutationFunction, MutationResult, QueryResult } from '@apollo/client';
import { Mutation, Query } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, CircularProgress, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Marker } from '../assets/svg';
import { RadioPlace } from '../components/RadioPlace';
import { CurrentUserFields } from '../fragments/current-user-fields';
import { withUser } from '../hoc/withUser';
import { changeThemeColor } from '../lib/helpers';
import { setColors } from '../redux/app/actions';
import { Colors } from '../redux/app/reducer';
import { setUser } from '../redux/auth/actions';

const PLACES = gql`
    query Places($id: String!) {
        type(id: $id) {
            places(
                sortBy: "name"
                sortOrder: "desc"
            ) {
                edges {
                    id
                    name
                    partner {
                        name
                        logo
                        description
                    }
                }
            }
        }
    }
`;

const CREATE_TREE = gql`
    ${CurrentUserFields}
    mutation CreateTree(
        $typeId: String
        $placeId: String
    ) {
        createTree(input: {
            typeId: $typeId
            placeId: $placeId
        }) {
            user {
                ...CurrentUserFields
            }
        }
    }
`;

type ChoosePlaceProps = {
    setColors: (colors: Colors) => void;
    setUser: (user: any) => void;
    user: any;
};

const mapDispatchToProps = {
    setColors,
    setUser
};

export const ChoosePlace = connect(null, mapDispatchToProps)(withUser((props: ChoosePlaceProps): JSX.Element => {
    const redirect = useNavigate();

    const { typeId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const {
        setColors,
        setUser,
        user
    } = props;

    useEffect(() => {
        changeThemeColor('#fff');
        setColors({
            top: '#fff',
            bottom: '#fff'
        });
    }, []);

    return (
        <Mutation
            mutation={CREATE_TREE}
            variables={{

            }}
            onError={(err: ApolloError) => {
                enqueueSnackbar(err.message, {
                    variant: 'error'
                });
            }}
            onCompleted={({ createTree }: any) => {
                setUser(createTree.user);
                redirect('/home?confetti=true');
            }}
        >
            {(mutate: MutationFunction, { loading }: MutationResult) => (
                <Query
                    fetchPolicy="no-cache"
                    query={PLACES}
                    variables={{
                        id: typeId
                    }}
                    onError={(err: ApolloError) => {
                        //
                    }}
                >
                    {({ data, loading: fetching }: QueryResult) => (
                        <Container maxWidth="xs">
                            <Formik
                                initialValues={{
                                    placeId: null
                                }}
                                onSubmit={values => {
                                    mutate({
                                        variables: Object.assign(values, {
                                            typeId
                                        })
                                    });
                                }}
                            >
                                {({ values, handleSubmit, getFieldHelpers }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        autoComplete="off"
                                        noValidate
                                    >
                                        <Box padding={2}>
                                            <Stack
                                                alignItems="center"
                                                sx={{
                                                    marginY: 5
                                                }}
                                            >
                                                <Avatar
                                                    sx={{
                                                        width: 80,
                                                        height: 80,
                                                        backgroundColor: '#f0f0f0'
                                                    }}
                                                >
                                                    <SvgIcon
                                                        component={Marker}
                                                        fontSize="large"
                                                        inheritViewBox
                                                    />
                                                </Avatar>
                                                <Typography
                                                    variant="h6"
                                                    textAlign="center"
                                                    sx={{
                                                        marginTop: 1
                                                    }}
                                                >
                                                    Байршил сонгоно уу
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    color="text.secondary"
                                                    textAlign="center"
                                                >
                                                    Та өөрийн модоо аль байршилд тарьмаар байна вэ?
                                                </Typography>
                                            </Stack>
                                            {fetching && (
                                                <Box textAlign="center">
                                                    <CircularProgress />
                                                </Box>
                                            )}
                                            {data && (
                                                <Stack spacing={1}>
                                                    {data.type.places.edges.map(({ id, name, partner }: any) => (
                                                        <RadioPlace
                                                            key={id}
                                                            name={name}
                                                            checked={id === values.placeId}
                                                            partnerName={partner.name}
                                                            partnerLogo={partner.logo}
                                                            partnerDescription={partner.description}
                                                            onChangeValue={() => {
                                                                getFieldHelpers('placeId').setValue(id);
                                                            }}
                                                        />
                                                    ))}
                                                </Stack>
                                            )}
                                            <Stack
                                                spacing={1}
                                                sx={{
                                                    marginTop: 2
                                                }}
                                            >
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    type="submit"
                                                    disabled={values.placeId === null}
                                                    loading={loading}
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    Тарих
                                                </LoadingButton>
                                                <Button
                                                    component={Link}
                                                    to="/choose/type"
                                                    color="primary"
                                                    size="large"
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    Буцах
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Container>
                    )}
                </Query>
            )}
        </Mutation>
    );
}));
