import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query, Subscription } from '@apollo/client/react/components';
import { Box, Stack, StackProps } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { setUserDrops } from '../redux/auth/actions';
import { Bubble } from './Bubble';

const USER = gql`
    query User($id: String!) {
        user(id: $id) {
            unclaimedRewards (
                sortBy: "id"
                sortOrder: "desc"
                take: 5
            ) {
                edges {
                    id
                    drops
                }
            }
        }
    }
`;

const ON_REWARD_CLAIMED = gql`
    subscription OnRewardClaimed($userId: String!) {
        rewardClaimed(userId: $userId) {
            id
        }
    }
`;

type Reward = {
    id: string;
    drops: number;
};

type StealableBubblesProps = StackProps & {
    setUserDrops: (drops: number, currentTreeDrops?: number) => void;
    charges: number;
    onChangeCharges: (charges: number) => void;
    userId: string;
    userNickname: string;
};

const mapDispatchToProps = {
    setUserDrops
};

export const StealableBubbles = connect(null, mapDispatchToProps)(({ setUserDrops, ...props }: StealableBubblesProps): JSX.Element | null => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        charges,
        onChangeCharges,
        userId,
        userNickname,
        ...other
    } = props;

    const [
        rewards,
        setRewards
    ] = useState<Reward[]>([]);

    return (
        <Fragment>
            <Subscription
                subscription={ON_REWARD_CLAIMED}
                variables={{
                    userId
                }}
                onSubscriptionData={({ subscriptionData }: any) => {
                    setRewards(rewards => rewards.filter(({ id }) => id !== subscriptionData.data.rewardClaimed.id));
                }}
            />
            <Query
                fetchPolicy="no-cache"
                query={USER}
                variables={{
                    id: userId
                }}
                onError={(err: ApolloError) => {
                    //
                }}
                onCompleted={(data: any) => {
                    setRewards(data.user.unclaimedRewards.edges);
                }}
            >
                {({ data }: QueryResult) => (
                    <Box {...other}>
                        <Stack
                            justifyContent="center"
                            direction="row"
                            spacing={1}
                        >
                            {rewards.slice(0, 5).map(({ id, drops }: any, index: number) => (
                                <div key={id}>
                                    <Bubble
                                        id={id}
                                        drops={drops}
                                        charges={charges}
                                        onChangeCharges={onChangeCharges}
                                        onError={err => {

                                        }}
                                        onClaim={drops => {
                                            setUserDrops(drops);
                                        }}
                                    />
                                </div>
                            ))}
                        </Stack>
                    </Box>
                )}
            </Query>
        </Fragment>
    );
});